
export default {
    namespaced: true,
    state: {
        lines: [],
        taxRate: 0.075,
        quant: 1,
        loading: false
    },
    getters: {
        itemCount: state => state.lines.reduce((total, line) =>
            total + line.quantity, 0),
        taxPrice: state => state.lines.reduce((total, line) => 
            total + ((((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) * state.taxRate)), 0),
        subTotal: state => state.lines.reduce((total, line) =>
            total + ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)), 0),
        totalPrice: state => state.lines.reduce((total, line) =>
            total + ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) + (((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) * state.taxRate), 0),
    },
    mutations: {
        addBook(state, book) {
            state.loading = true
            let line = state.lines.find(line => line.book.id == book.id)
            if (line != null) {
                line.quantity ++;
                line.lineSubTotal += (line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)
                state.loading = false
            } else {
                let lineSubTotal = (1 * book.price) - (1 * book.price * book.percentage)
                state.lines.push({ book: book, quantity: 1, lineSubTotal: lineSubTotal })
                state.loading = false
            }
        },
        addProduct(state, {product, selectedOptionIndex, comment, selectedExtraIndex, selectedSauceIndex, selectedSideIndex, selectedBreadIndex}) {
            let line = state.lines.find(line => line.product.id == product.id & line.product.options[line.selectedOptionIndex].name == product.options[selectedOptionIndex].name);
            if (line != null) {
                line.quantity++;
                
                line.lineSubTotal += ((line.quantity * line.product.options[line.selectedOptionIndex].price) + (line.selectedExtraIndex != null ? line.product.extra[line.selectedExtraIndex].price : 0.00))
                
            } else {
                let lineSubTotal = ((1 * product.options[selectedOptionIndex].price) + (selectedExtraIndex != null ? product.extra[selectedExtraIndex].price : 0.00))
                state.lines.push({product: product, quantity: 1, selectedOptionIndex: selectedOptionIndex, comment: comment, selectedExtraIndex: selectedExtraIndex, selectedSauceIndex: selectedSauceIndex, selectedSideIndex: selectedSideIndex, selectedBreadIndex: selectedBreadIndex, lineSubTotal: lineSubTotal});
                
            }
        },
        changeQuantity(state, update) {
            update.line.quantity = update.quantity;
            update.line.lineSubTotal = ((update.quantity * update.line.book.price) - (update.quantity * update.line.book.price * update.line.book.percentage))
            
        },
        removeProduct(state, lineToRemove) {
            let index = state.lines.findIndex(line => line == lineToRemove);
            if (index > - 1) {
                state.lines.splice(index, 1)
            }
        },
        setCartData(state, data) {
            state.lines = data;
        },
        botCart(state, data) {
            state.lines = data;
        }
    },
    actions: {
        loadCartData(context) {
            let data = localStorage.getItem("shoppingcart");
            if (data != null) {
                context.commit("setCartData", JSON.parse(data));
            }
        },
        storeCartData(context) {
            localStorage.setItem("shoppingcart", JSON.stringify(context.state.lines));
        },
        clearCartData(context) {
            context.commit("setCartData", []);
        },
        initializeCart(context, store) {
            context.dispatch("loadCartData");
            store.watch(state => state.shoppingcart.lines,
                () => context.dispatch("storeCartData"), { deep: true })
        }
    }
}