<template>
    <div>
        <div class="tw-flex tw-w-12/12 tw-px-4 lg:tw-hidden">
            <div class="tw-flex tw-flex-col tw-w-full tw-mx-auto tw-items-center">
                <div class="tw-py-6">
                    <router-link to="/">
                        <h1 class="logo_font tw-font-bold tw-text-3xl">Adam's Pages</h1>
                    </router-link>   
                </div>
                <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between lg:tw-hidden lg:tw-justify-between lg:tw-px-9 tw-h-full">
                    <v-btn @click.stop="sideNav = !sideNav" icon large>
                        <svg width="20px" height="18px">
                            <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,-0.000 L20.000,-0.000 L20.000,2.000 L-0.000,2.000 L-0.000,-0.000 Z"></path>
                            <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,8.000 L15.000,8.000 L15.000,10.000 L-0.000,10.000 L-0.000,8.000 Z"></path>
                            <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,16.000 L20.000,16.000 L20.000,18.000 L-0.000,18.000 L-0.000,16.000 Z"></path>
                        </svg>
                        
                    </v-btn>
                    <div class="tw-w-4/5">
                        <search-box></search-box>
                    </div>
                </div>
            </div>   
        </div>
        <div class="tw-hidden lg:tw-flex tw-flex-col tw-divide-y tw-border-b">
            <div class="tw-w-full tw-flex tw-py-4 tw-px-10 tw-justify-between">
                <div class="tw-flex tw-flex-row tw-space-x-6">
                    <router-link to="/faq">
                        <div class="tw-flex tw-flex-row tw-space-x-2 tw-items-center tw-text-black hover:tw-text-primary-900">
                            <v-icon color="black" small>
                                mdi-help-circle-outline
                            </v-icon>
                            <span>Can we help you?</span>
                        </div>

                    </router-link>
                    
                    <router-link to="/contact">
                        <div class="tw-flex tw-flex-row tw-space-x-2 tw-items-center tw-text-black">
                            <v-icon color="black" small>
                                mdi-phone
                            </v-icon>
                            <span>
                                +234(814)864-7822
                            </span>
                        </div>

                    </router-link>    
                        
                    
                </div>
                <div class="tw-flex tw-flex-row tw-space-x-4">
                    <v-btn color="white" icon to="/contact" depressed small>
                        <v-icon color="primary-800" medium>mdi-map-marker-outline</v-icon>
                    </v-btn>
                    <!-- <Cart /> -->
                </div>
                
            </div>
            <div class="tw-h-28 tw-w-full tw-px-10 tw-py-10 tw-flex tw-flex-row tw-space-x-12 tw-items-center">
                <v-btn @click.stop="sideNav = !sideNav" icon large>
                    <svg width="20px" height="18px">
                        <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,-0.000 L20.000,-0.000 L20.000,2.000 L-0.000,2.000 L-0.000,-0.000 Z"></path>
                        <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,8.000 L15.000,8.000 L15.000,10.000 L-0.000,10.000 L-0.000,8.000 Z"></path>
                        <path fill-rule="evenodd" fill="rgb(0, 0, 0)" d="M-0.000,16.000 L20.000,16.000 L20.000,18.000 L-0.000,18.000 L-0.000,16.000 Z"></path>
                    </svg>   
                </v-btn>
                <div>
                    <router-link to="/">

                        <div class="tw-flex tw-space-x-2 tw-items-center">
                            <svg width="36" height="36" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="200" height="200" fill="white"/>
                                <rect x="31.4648" y="42" width="147.535" height="58" rx="29" fill="#0079D3" stroke="black" stroke-width="8"/>
                                <rect x="31.4648" y="100" width="147.535" height="58" rx="29" fill="#C80B61" stroke="black" stroke-width="8"/>
                                <mask id="path-3-outside-1_930:1154" maskUnits="userSpaceOnUse" x="27.4648" y="38" width="69" height="66" fill="black">
                                <rect fill="white" x="27.4648" y="38" width="69" height="66"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 96H62.7904C76.5976 96 87.7904 84.8071 87.7904 71C87.7904 57.1929 76.5975 46 62.7904 46H35.4648V96Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 96H62.7904C76.5976 96 87.7904 84.8071 87.7904 71C87.7904 57.1929 76.5975 46 62.7904 46H35.4648V96Z" fill="white"/>
                                <path d="M35.4648 96H27.4648V104H35.4648V96ZM35.4648 46V38H27.4648V46H35.4648ZM62.7904 88H35.4648V104H62.7904V88ZM79.7904 71C79.7904 80.3888 72.1793 88 62.7904 88V104C81.0158 104 95.7904 89.2254 95.7904 71H79.7904ZM62.7904 54C72.1793 54 79.7904 61.6112 79.7904 71H95.7904C95.7904 52.7746 81.0158 38 62.7904 38V54ZM35.4648 54H62.7904V38H35.4648V54ZM27.4648 46V96H43.4648V46H27.4648Z" fill="black" mask="url(#path-3-outside-1_930:1154)"/>
                                <mask id="path-5-outside-2_930:1154" maskUnits="userSpaceOnUse" x="27.4648" y="96" width="69" height="66" fill="black">
                                <rect fill="white" x="27.4648" y="96" width="69" height="66"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 154H62.7904C76.5976 154 87.7904 142.807 87.7904 129C87.7904 115.193 76.5975 104 62.7904 104H35.4648V154Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 154H62.7904C76.5976 154 87.7904 142.807 87.7904 129C87.7904 115.193 76.5975 104 62.7904 104H35.4648V154Z" fill="white"/>
                                <path d="M35.4648 154H27.4648V162H35.4648V154ZM35.4648 104V96H27.4648V104H35.4648ZM62.7904 146H35.4648V162H62.7904V146ZM79.7904 129C79.7904 138.389 72.1793 146 62.7904 146V162C81.0158 162 95.7904 147.225 95.7904 129H79.7904ZM62.7904 112C72.1793 112 79.7904 119.611 79.7904 129H95.7904C95.7904 110.775 81.0158 96 62.7904 96V112ZM35.4648 112H62.7904V96H35.4648V112ZM27.4648 104V154H43.4648V104H27.4648Z" fill="black" mask="url(#path-5-outside-2_930:1154)"/>
                                <rect x="25" y="38" width="10.4651" height="66" fill="white"/>
                                <rect x="25" y="96" width="10.4651" height="66" fill="white"/>
                                <rect x="30.8145" y="38" width="23.2558" height="8" rx="4" fill="black"/>
                                <rect x="30.8145" y="96" width="23.2558" height="8" rx="4" fill="black"/>
                                <rect x="30.8145" y="154" width="23.2558" height="8" rx="4" fill="black"/>
                            </svg>
                            <span class="logo_font tw-font-bold tw-text-2xl">Adam's Pages</span>

                        </div>
                    </router-link>
                </div>
                <div class="tw-w-2/5">
                    <search-box></search-box>
                </div>

            </div>

        </div>
        <v-navigation-drawer temporary fixed app v-model="sideNav">
            <div class="tw-flex tw-flex-col tw-justify-center tw-min-h-screen">
                <v-list nav>
                    <v-list-group
                        v-for="item in list"
                        :key="item.title"
                        v-model="item.active"
                        no-action  
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="child in item.items"
                            :key="child.text"
                            :to="child.route"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="child.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>

//import Cart from './Cart.vue'
import SearchBox from "./SearchBox.vue"
export default {
    name: "NavView",
    components: {
        //Cart
        SearchBox
    },
    data() {
        return {
            sideNav: false,
            items: [],
            list: [
                {
                    action: 'mdi-book-open-page-variant-outline',
                    items: [
                        // { title: 'Business & Economics', route: "/category/business&economics" },
                        // {
                        //     title: "Fiction",
                        //     route: "/category/fiction"
                        // },
                        // {
                        //     title: "Juvenile Fiction",
                        //     route: "/category/juvenilefiction"
                        // },
                        // {
                        //     title: "Self-Help",
                        //     route: "/category/self-help"
                        // },
                        {
                            text: "Fiction",
                            route: "/category/fiction",
                            category: "Fiction"
                        },
                        {
                            text: "Non Fiction",
                            route: "/category/non-fiction",
                            category: "Non-fiction"
                        },
                        {
                            text: "Health",
                            route: "/category/health",
                            category: "Health"
                        },
                        {
                            text: "Children",
                            route: "/category/children",
                            category: "Children"
                        },
                        {
                            text: "Young Adult",
                            route: "/category/youngadult",
                            category: "Young Adult"
                        },
                        // {
                        //     text: "Juvenile Fiction",
                        //     route: "/category/juvenilefiction",
                        //     category: "Juvenile Fiction"
                        // },
                        {
                            text: "Parenting",
                            route: "/category/parenting",
                            category: "Parenting"
                        },
                        {
                            text: "Religion",
                            route: "/category/religion",
                            category: "Religion"
                        },
                        {
                            text: "Business & Economics",
                            route: "/category/business&economics",
                            category: "Business & Economics"

                        },
                        {
                            text: "Self Help",
                            route: "/category/self-help",
                            category: "Self-Help"
                        },
                        {
                            text: "Literature",
                            route: "/category/literature",
                            category: "literature"
                        },
                    ],
                    title: 'Categories',
                },
                {
                    action: 'mdi-help',
                    items: [
                        {
                            title: "Contact us",
                            route: "/contact"
                        },
                        {
                            title: "FAQ",
                            route: "/faq"
                        },
                        {
                            title: "Track Order",
                            route: "/track-order"
                        },
                    ],
                    title: 'Customer Service',
                },
                {
                    action: 'mdi-office-building',
                    
                    items: [
                        {
                            title: "About us",
                            route: "/about"
                        },
                        
                    ],
                    title: 'Company',
                },
                {
                    action: 'mdi-security',
                    items: [
                        {
                            title: "Term of use",
                            route: "/term"
                        },
                        {
                            title: "Privacy Policy",
                            route: "/privacy"
                        },
                    ],
                    title: 'Policy',
                },
            ]
        }
    }
}
</script>

<style>
@import url('http://fonts.cdnfonts.com/css/recoleta');
.logo_font {
    font-family: 'Recoleta', sans-serif;
}
</style>