<template>
    <div class="tw-container tw-space-y-12 tw-flex tw-flex-col tw-items-center tw-w-full tw-py-4 md:tw-py-12 md:tw-max-w-4xl tw-mx-auto">
        <h1 class="tw-font-bold tw-text-lg md:tw-text-4xl">Frequently Asked Questions</h1>
        <section class="tw-px-4 md:tw-px-0">
            <div class="tw-container tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-8">
                <h1 class="tw-font-medium tw-text-base lg:tw-text-2xl">Shopping</h1>
                <v-expansion-panels mandatory flat class="tw-border-black tw-border-t tw-border-r tw-border-l tw-rounded-none" accordion>
                    
                    <v-expansion-panel
                        v-for="(item,i) in clients"
                        :key="i"
                        
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <div>
                                    {{item.title}}
                                </div>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            {{item.detail}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

            </div>
        </section>
        <section class="tw-px-4 md:tw-px-0">
            <div class="tw-container tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-8">
                <h1 class="tw-font-medium tw-text-base lg:tw-text-2xl">Payment</h1>
                <v-expansion-panels mandatory flat class="tw-border-black tw-border-t tw-border-r tw-border-l tw-rounded-none" accordion>
                    
                    <v-expansion-panel
                        v-for="(item,i) in clients"
                        :key="i"
                        
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <div>
                                    {{item.title}}
                                </div>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            {{item.detail}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

            </div>
        </section>
        <section class="tw-px-4 md:tw-px-0">
            <div class="tw-container tw-flex tw-flex-col tw-space-y-3 md:tw-space-y-8">
                <h1 class="tw-font-medium tw-text-base lg:tw-text-2xl">Others</h1>
                <v-expansion-panels mandatory flat class="tw-border-black tw-border-t tw-border-r tw-border-l tw-rounded-none" accordion>
                    
                    <v-expansion-panel
                        v-for="(item,i) in store"
                        :key="i"
                        
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <div>
                                    {{item.title}}
                                </div>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            {{item.detail}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clients: [
                { title: "Do you help source for books not available in the store?", detail: "Yes we do.", icon: "mdi-calendar-account-outline" },
                { title: "Do you ship books?", detail: "Yes we ship everywhere.", icon: "mdi-priority-high" },
                { title: "Do you gift wrap?", detail: "Yes we do.", icon: "mdi-priority-high" },
                { title: "Do you sell only new books?", detail: "We sell mostly New books. However we have a selection of neatly used books in the store. You can come in to read or buy at very affordable prices.", icon: "mdi-priority-high" },
                { title: "How do I order books that aren’t in-store or listed on your website?", detail: "We can help you order for books currently not in the store or listed on the website.. send an email to adamspagesabuja@gmail.com", icon: "mdi-priority-high" },
            ],
            store: [
                { title: "Do you have a book club?", detail: "No we don't. However we do host literary events and book discussions.", icon: "mdi-gift-outline" },
                { title: "What are your opening hours?", detail: "9 am to 6pm on Mondays to Saturdays.", icon: "mdi-card-account-details-outline" },
                
                { title: "Do you charge for the use of your Reading Space?", detail: "Reading Room is free for use, if you purchased books from the store, otherwise we charge 1500 for the day. Between..9am and 5pm.", icon: "mdi-priority-high" },
                
                { title: "Do you serve Coffee or Tea?", detail: "Yes we do", icon: "mdi-priority-high" },
                
            ],
        }
    }
}
</script>