<template>
    <v-footer class="tw-mt-12 white tw-border-t tw-border-black" padless>
        
        <div class="tw-px-4 tw-flex tw-mt-10 tw-flex-col tw-w-full tw-items-center tw-space-y-6">
            <div class="tw-flex tw-flex-col tw-space-y-2 tw-text-center">
                <h1 class="tw-font-bold tw-text-2xl lg:tw-text-3xl">Join Our Newsletter</h1>
                <p>Signup to be the first to hear about exclusive deals, special offers and upcoming collections</p>
            </div>
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-3 md:tw-space-x-3 md:tw-space-y-0 tw-items-center tw-justify-center tw-w-full">
                <div class="tw-w-full lg:tw-w-2/5">
                    <v-text-field v-model="email" hide-details class="tw-rounded-none" flat outlined placeholder="Enter email for weekly updates"></v-text-field>
                </div>
                <div class="tw-w-full lg:tw-w-1/5">
                    <v-btn :loading="loading" :disabled="success" @click="subscribe" x-large class="primary tw-normal-case" depressed tile block>{{ success ? "Subscribed" : "Subscribe" }}</v-btn>
                </div>
                
            </div>

        </div>
        <div class="tw-mt-8 tw-grid tw-grid-cols-1 tw-gap-5 tw-w-screen tw-bg-white tw-px-6 lg:tw-px-12 tw-py-12 lg:tw-grid-cols-6 lg:tw-gap-1">
            <div class="tw-hidden lg:tw-flex tw-flex-col tw-space-y-6 tw-col-span-2">
                <router-link to="/">

                        <div class="tw-flex tw-space-x-2 tw-items-center">
                            <svg width="36" height="36" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="200" height="200" fill="white"/>
                                <rect x="31.4648" y="42" width="147.535" height="58" rx="29" fill="#0079D3" stroke="black" stroke-width="8"/>
                                <rect x="31.4648" y="100" width="147.535" height="58" rx="29" fill="#C80B61" stroke="black" stroke-width="8"/>
                                <mask id="path-3-outside-1_930:1154" maskUnits="userSpaceOnUse" x="27.4648" y="38" width="69" height="66" fill="black">
                                <rect fill="white" x="27.4648" y="38" width="69" height="66"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 96H62.7904C76.5976 96 87.7904 84.8071 87.7904 71C87.7904 57.1929 76.5975 46 62.7904 46H35.4648V96Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 96H62.7904C76.5976 96 87.7904 84.8071 87.7904 71C87.7904 57.1929 76.5975 46 62.7904 46H35.4648V96Z" fill="white"/>
                                <path d="M35.4648 96H27.4648V104H35.4648V96ZM35.4648 46V38H27.4648V46H35.4648ZM62.7904 88H35.4648V104H62.7904V88ZM79.7904 71C79.7904 80.3888 72.1793 88 62.7904 88V104C81.0158 104 95.7904 89.2254 95.7904 71H79.7904ZM62.7904 54C72.1793 54 79.7904 61.6112 79.7904 71H95.7904C95.7904 52.7746 81.0158 38 62.7904 38V54ZM35.4648 54H62.7904V38H35.4648V54ZM27.4648 46V96H43.4648V46H27.4648Z" fill="black" mask="url(#path-3-outside-1_930:1154)"/>
                                <mask id="path-5-outside-2_930:1154" maskUnits="userSpaceOnUse" x="27.4648" y="96" width="69" height="66" fill="black">
                                <rect fill="white" x="27.4648" y="96" width="69" height="66"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 154H62.7904C76.5976 154 87.7904 142.807 87.7904 129C87.7904 115.193 76.5975 104 62.7904 104H35.4648V154Z"/>
                                </mask>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4648 154H62.7904C76.5976 154 87.7904 142.807 87.7904 129C87.7904 115.193 76.5975 104 62.7904 104H35.4648V154Z" fill="white"/>
                                <path d="M35.4648 154H27.4648V162H35.4648V154ZM35.4648 104V96H27.4648V104H35.4648ZM62.7904 146H35.4648V162H62.7904V146ZM79.7904 129C79.7904 138.389 72.1793 146 62.7904 146V162C81.0158 162 95.7904 147.225 95.7904 129H79.7904ZM62.7904 112C72.1793 112 79.7904 119.611 79.7904 129H95.7904C95.7904 110.775 81.0158 96 62.7904 96V112ZM35.4648 112H62.7904V96H35.4648V112ZM27.4648 104V154H43.4648V104H27.4648Z" fill="black" mask="url(#path-5-outside-2_930:1154)"/>
                                <rect x="25" y="38" width="10.4651" height="66" fill="white"/>
                                <rect x="25" y="96" width="10.4651" height="66" fill="white"/>
                                <rect x="30.8145" y="38" width="23.2558" height="8" rx="4" fill="black"/>
                                <rect x="30.8145" y="96" width="23.2558" height="8" rx="4" fill="black"/>
                                <rect x="30.8145" y="154" width="23.2558" height="8" rx="4" fill="black"/>
                            </svg>
                            <span class="logo_font tw-text-3xl">ADAM'S PAGES</span>

                        </div>
                    </router-link>

                    <div class="tw-text-gray-500">
                        <div>Machima Plaza</div>
                        <div>No 2 Mambolo Close Off Sultan Abubakar Way</div>
                        <div>Wuse Zone 3, Abuja, Nigeria</div>
                        <!-- <div>Abuja, Nigeria</div> -->
                    </div>

                    <div class="tw-flex tw-flex-col tw-space-y-1 tw-text-gray-500">
                        <div>help@theadamspages.com</div>
                        <div>+2348148647822</div>
                    </div>

                    <div class="tw-flex tw-space-x-3">
                        <div>
                            <v-btn href="https://www.instagram.com/adamspages/" target="_blank" fab x-small outlined>
                                <v-icon>mdi-instagram</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn href="https://www.facebook.com/adamspagesabuja/" target="_blank" fab x-small outlined>
                                <v-icon>mdi-facebook</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn href="https://twitter.com/adamspagesabuja" target="_blank" fab x-small outlined>
                                <v-icon>mdi-twitter</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn fab x-small outlined>
                                <v-icon>mdi-linkedin</v-icon>
                            </v-btn>
                        </div>
                    </div>

            </div>
                
            
            <div class="tw-flex tw-flex-col tw-space-y-4">
                <div class="tw-font-medium">
                    <h2 class="tw-font-bold tw-text-lg tw-text-gray-600">Customer Service</h2>
                </div>
                <div  class="tw-flex-col tw-flex tw-space-y-3 tw-w-3/5">
                    <a v-for="(item, i) in solutions" :key="i" :href="item.route" class="tw-text-sm tw-font-normal tw-text-gray-500 hover:tw-text-gray-900">
                        <span class="tw-text-gray-500 hover:tw-text-primary-800">{{item.text}}</span>
                    </a>
                </div>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-4">
                <div class="tw-font-medium">
                    <h2 class="tw-font-bold tw-text-lg tw-text-gray-600">Policy</h2>
                </div>
                <div class="tw-flex-col tw-flex tw-space-y-3 tw-w-5/5">
                    <a v-for="(item, i) in features" :key="i" :href="item.route" class="tw-text-sm tw-font-normal tw-text-gray-500 hover:tw-text-gray-900">
                        <span class="tw-text-gray-500 hover:tw-text-primary-800">{{item.text}}</span>
                    </a>
                </div>
            </div>
            <!-- <div class="tw-flex-col tw-flex tw-space-y-6">
                <div class="tw-text-white tw-font-medium">
                    <h2>CONTACT US</h2>
                </div>
                <div class="tw-flex tw-flex-col tw-text-white">
                    <div class="tw-flex tw-flex-row tw-space-x-4">
                        <div>
                            <v-icon>mdi-email</v-icon>
                        </div>
                        <div class="tw-flex tw-flex-col">
                            <div>support@koboservices.com</div>
                            <div>Monday to Friday</div>
                            <div>9:00am-9:00pm</div>
                            <div>Saturday-Sunday</div>
                            <div>9:00am-6:00pm</div>
                            <div>(excluding public holidays)</div>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-row tw-space-x-4">
                        <div>
                            <v-icon>mdi-phone</v-icon>
                        </div>
                        <div class="tw-flex tw-flex-col">
                            <div>+234 8088887142</div>
                            <div>Monday to Friday</div>
                            <div>9:00am-9:00pm</div>
                            <div>Saturday-Sunday</div>
                            <div>9:00am-6:00pm</div>
                            <div>(excluding public holidays)</div>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-row tw-space-x-4 tw-mt-4">
                        <div>
                            <v-icon>mdi-office-building</v-icon>
                        </div>
                        <div class="tw-flex tw-flex-col">
                            <div>Flat D, Block 28</div>
                            <div>111 Road, Gwarimpa II Housing Estate</div>
                            <div>Abuja</div>
                            <div>F.C.T, Nigeria</div>
                
                        </div>
                    </div>

                </div>
                
            </div> -->
            <div class="tw-flex tw-flex-col tw-space-y-4 tw-col-span-1">
                <div class="tw-font-medium">
                    <h2 class="tw-font-bold tw-text-lg tw-text-gray-600">Company</h2>
                </div>
                <div class="tw-flex-col tw-flex tw-space-y-3 tw-w-3/5">
                    <a v-for="(item, i) in company" :key="i" :href="item.route" class="tw-text-sm tw-font-normal tw-text-gray-500 hover:tw-text-gray-900">
                        <span class="tw-text-gray-500 hover:tw-text-primary-800">{{item.text}}</span>
                    </a>
                </div>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-4 tw-col-span-1">
                <div class="tw-font-medium">
                    <h2 class="tw-font-bold tw-text-lg tw-text-gray-600">Categories</h2>
                </div>
                <div class="tw-flex-col tw-flex tw-space-y-3 tw-w-3/5">
                    <a v-for="(item, i) in categories" :key="i" :href="item.route" class="tw-text-sm tw-font-normal tw-text-gray-500 hover:tw-text-gray-900">
                        <span class="tw-text-gray-500 hover:tw-text-primary-800">{{item.text}}</span>
                    </a>
                </div>
            </div>
            
        </div>
    </v-footer>
</template>

<script>

import { mapState } from "vuex"

export default {
    name: "FooterView",
    watch: {
        email() {
            if (this.success) {
                this.success = false
            }
        }
    },
    computed: {
        ...mapState({loading:state=>state.contact.loading})
    },
    methods: {
        async subscribe() {
            const response = await this.$store.dispatch("subscribe", this.email)
            this.success = response.success
        }
    },
    data: () => ({
        email: "",
        success: false,
        links: [
            {
                text: "Home",
                route: "/"
            },
            {
                text: "Menu",
                route: "/menu"
            },
            {
                text: "Contact",
                route: "/contact"
            },
            {
                text: "Location",
                route: "/location"
            },
            {
                text: "Gallery",
                route: "/gallery"
            }
        ],
        company: [
            {
                text: "About",
                route: "/about"
            },
            
            // {
            //     text: "Careers",
            //     route: "/careers"
            // },
            // {
            //     text: "Contact us",
            //     route: "/contact-us"
            // },
            // {
            //     text: "Customers Privacy Policy",
            //     route: "/customers-privacy"
            // },
            // {
            //     text: "Providers Privacy Policy",
            //     route: "/providers-policy"
            // },
            // {
            //     text: "Terms and conditions",
            //     route: "/terms"
            // },
        ],
        solutions: [
            {
                text: "Contact Us",
                route: "/contact"
            },
            {
                text: "FAQ",
                route: "/faq"
            },
            // {
            //     text: "Returns",
            //     route: "/return-policy"
            // },
            // {
            //     text: "Store Hours",
            //     route: "/hours"
            // },
            {
                text: "Track Order",
                route: "/track-order"
            }
        ],
        features: [
            {
                text: "Term of use",
                route: "/term"
            },
            {
                text: "Privacy",
                route: "/privacy"
            },
            // {
            //     text: "Return Policy",
            //     route: "/return"
            // },
            
        ],
        categories: [
            {
                text: "Business & Economics",
                route: "/category/business&economics"
            },
            {
                text: "Fiction",
                route: "/category/fiction"
            },
            {
                text: "Juvenile Fiction",
                route: "/category/juvenilefiction"
            },
            {
                text: "Self-Help",
                route: "/category/self-help"
            },
            // {
            //     text: "Summer Reading",
            //     route: "/category/summer-reading"
            // },
            // {
            //     text: "Teens & YA",
            //     route: "/category/teens-ya"
            // },    
            
        ]
    })
}
</script>