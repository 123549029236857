<template>
    <v-container>
        
        <v-row v-if="itemCount > 0">
            <v-col cols="12" lg="7" md="6">
                <v-row v-if="itemCount > 0">
                    <v-col cols="12">
                        <CLine v-for="line in lines" :key="line.book.id"
                            v-bind:line="line"
                            @quantity="handleQuantityChange(line, $event)"
                            @remove="remove" />
                        
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-card tile flat>
                            <v-card-title style="word-break:normal;">There are currently no items in your shopping cart.</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="5" md="6">
                <v-card tile flat dark class="primary">
                    <v-card-title class="font-weight-black">SUMMARY</v-card-title>
                    <v-row dense class="mx-0">
                        <v-col cols="5">
                            <v-card-text class="tw-text-base">Subtotal</v-card-text>
                        </v-col>
                        <v-col cols="7">
                            <v-card-text class="text-right tw-text-base">{{ subTotal | currency }}</v-card-text>
                        </v-col>
                        <v-col cols="5" class="mt-n7">
                            <v-card-text class="tw-text-base">Estimated Tax</v-card-text>
                        </v-col>
                        <v-col cols="7" class="mt-n7">
                            <v-card-text class="text-right tw-text-base">{{ taxPrice | currency }}</v-card-text>
                        </v-col>
                        <!-- <v-col cols="7" class="mt-n7">
                            <v-card-text class="tw-text-base">Estimated Shipping</v-card-text>
                        </v-col>
                        <v-col cols="5" class="mt-n7">
                            <v-card-text class="text-right tw-text-base">Free</v-card-text>
                        </v-col> -->
                        <v-col class="px-4" cols="12">
                            <v-divider class="white"></v-divider>
                        </v-col>
                        
                        <v-col cols="4">
                            <v-card-text class="font-weight-black tw-text-base">TOTAL</v-card-text>
                        </v-col>
                        <v-col cols="8" class="text-right">
                            <v-card-text class="font-weight-black tw-text-base">{{ totalPrice | currency }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12">
                                <v-btn x-large style="border-radius:0px;" block depressed dark class="secondary-700 font-weight-medium" to="/">
                                    CONTINUE SHOPPING
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn x-large style="border-radius:0px;" :disabled="itemCount > 0 ? false : true" to="/checkout" block depressed dark class="secondary-700 font-weight-medium">
                                    CHECKOUT
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <section v-else class="tw-container tw-p-2 md:tw-p-12 tw-w-full md:tw-max-w-2xl tw-mx-auto">
            <div class="tw-flex tw-flex-col tw-space-y-6 tw-items-center">
                <div>
                    <p class="tw-text-3xl">There are currently no items in your shopping cart.</p>
                </div>
                <div class="tw-w-full">
                    <v-btn x-large style="border-radius:0px;text-transform:none;" block depressed dark class="primary-800" to="/">
                        Continue shopping
                    </v-btn>
                </div>
                <div class="tw-w-full">
                    <v-btn x-large style="border-radius:0px;text-transform:none;" to="/checkout" block depressed outlined color="primary-800">
                        Sign in to see your shopping cart
                    </v-btn>
                </div>

            </div>
                
        </section>
        <!-- <v-row >
            <v-col cols="12" lg="7" md="6">
                <v-row>
                    <v-col cols="12">
                        <v-card tile flat>
                            
                            <v-card-title class="tw-text-center" style="word-break:normal;">There are currently no items in your shopping cart.</v-card-title>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn x-large style="border-radius:0px;text-transform:none;" block depressed dark class="primary-800" to="/">
                                            Continue shopping
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn x-large style="border-radius:0px;text-transform:none;" to="/checkout" block depressed outlined color="primary-800">
                                            Sign In to see your shopping cart
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>

import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import CLine from "../components/CartLine.vue";
//import PickupOnly from "./PickupOnly";

export default {
    // mounted: function() {
    //     this.clearCart()
    // },
    // created: function () {
    //     this.clearCart()
    // },
    data: function() {
        return {
            disabled: false
        }
    },
    components: { CLine },
    computed: {
        ...mapState({ lines: state => state.shoppingcart.lines }),
        ...mapGetters({ totalPrice: "shoppingcart/totalPrice", taxPrice: "shoppingcart/taxPrice", subTotal: "shoppingcart/subTotal", itemCount: "shoppingcart/itemCount" })
    },
    methods: {
        ...mapMutations({
            change: "shoppingcart/changeQuantity",
            remove: "shoppingcart/removeProduct"
        }),
        handleQuantityChange(line, $event) {
            this.change({ line, quantity: $event });
        },
        ...mapActions({
            clearCart: "shoppingcart/clearCartData"
        })
    }
}
</script>