<template>
  <v-app>
    <Nav />
    <v-main>
      <router-view/>
    </v-main>
    
    <Footer />
    <!-- <AddToCart /> -->
  </v-app>
</template>

<script>

import { mapActions } from 'vuex'
import Nav from "./components/Nav.vue"
import Footer from './components/Footer.vue'
// import AddToCart from './components/AddToCart.vue'

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
    // AddToCart
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({
      getData: "getData",
      initializeCart: "shoppingcart/initializeCart"
    })
  },
  created() {
    this.initializeCart(this.$store)
  }
};
</script>

<style>
#app {
  font-family: 'Lato', sans-serif;
}
</style>
