import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import ShoppingBasket from '../views/ShoppingBasket.vue'
import Book from '../views/Book.vue'
import BookDetail from '../views/BookDetail.vue'
import Blob from '../views/Blob.vue'
import Contact from '../views/ContactView.vue'
import FAQ from '../views/FAQ.vue'
import Checkout from '../views/CheckoutView.vue'
import Category from '../views/Category.vue'
import CategoryDetail from '../views/CategoryDetail.vue'
import OrderReceived from '../views/OrderReceived.vue'
import TrackOrder from '../views/TrackOrder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/blob',
    name: 'Blob',
    component: Blob
  },
  {
    path: '/basket',
    name: 'Basket',
    component: ShoppingBasket
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/track-order',
    name: 'TrackOrder',
    component: TrackOrder
  },
  // {
  //   path: '/books/',
  //   name: 'BookDetail',
  //   component: BookDetail
  // },
  {
    path: '/book/:id',
    component: Book,
    children: [
      { path: '', name: 'BookDetail', component: BookDetail }
    ]
  },
  {
    path: '/order/:id',
    name: "OrderReceived",
    component: OrderReceived,
    
  },
  {
    path: '/category/',
    component: Category,
    children: [
      { path: ':id', name: 'CategoryDetail', component: CategoryDetail }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
