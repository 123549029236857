<template>
    <div>
        <v-card @click="goToDetail(item)" flat tile hover outlined class="tw-w-full tw-h-64 hover:tw-border-black hover:tw-shadow-lg">
          <div class="tw-w-full tw-h-full tw-flex tw-flex-row tw-items-center tw-space-x-8 tw-p-4">
            <div class="tw-self-center">
              <v-img style="max-width:150px;" :src="item.quick_info.imageLinks.thumbnail" :lazy-src="item.quick_info.imageLinks.thumbnail" class="tw-object-cover tw-h-52 tw-align-middle" alt=""></v-img>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-3">
                
              <div>
                <h2 class="grey--text text--darken-4 tw-text-base tw-font-bold tw-no-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden">
                    {{item.quick_info.title}}
                </h2>
                <a style="max-width:150px" class="grey--text text--darken-4 tw-text-sm tw-no-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden" href="#">
                    {{item.quick_info.authors[0]}}
                </a>
              </div>
              <div class="tw-w-4/5">
                    <p class="tw-line-clamp-3">
                        {{item.volumeInfo.description}}
                    </p>
                    
                    
              </div>
              <div v-if="item.discount" class="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                  <span class="tw-font-bold tw-text-sm">
                      {{(item.quick_info.retail_price * (1-item.quick_info.percentage)) | currency}}
                  </span>
                  <span class="tw-font-bold tw-text-sm tw-line-through tw-text-gray-400">
                      {{item.quick_info.retail_price | currency}}
                  </span>
              </div>
              <div v-else class="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                  <span class="tw-font-bold tw-text-sm">
                      {{item.quick_info.retail_price | currency}}
                  </span>
              </div>  
            </div>
          </div>
        </v-card>
    </div>
    
</template>

<script>
export default {
    props: {
        item: { type: Object, required: true }
    },
    methods: {
        goToDetail(item) {
            //this.$store.dispatch("selectBook", item)
            this.$router.push({ name: "BookDetail", params: { id: item.id, book: item } })
        }
    }
}
</script>