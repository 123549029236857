<template>
    <div class="tw-container tw-space-y-4 lg:tw-space-y-12 tw-flex tw-bg-pink-50 tw-flex-col tw-items-center tw-w-full tw-py-4 tw-px-2 md:tw-py-12 md:tw-px-10 md:tw-max-w-full tw-mx-auto">
        <h1 class="tw-font-bold tw-text-2xl md:tw-text-3xl lg:tw-text-4xl">Order Received</h1>
        <section class="tw-flex tw-py-3 lg:tw-py-12 tw-flex-col tw-divide-y tw-bg-white tw-border tw-justify-center tw-border-gray-200 tw-w-12/12 tw-container lg:tw-w-9/12">
            <div class="tw-flex tw-flex-col tw-px-3 lg:tw-px-8 tw-space-y-2 lg:tw-space-y-8">
                <h5 class="tw-font-semibold tw-text-lg lg:tw-text-xl tw-text-center tw-self-center">Thank you. Your order has been received.</h5>
                <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2 lg:tw-gap-4 tw-items-center">
                    <div class="tw-flex tw-flex-col">
                        <h6 class="tw-text-gray-700 tw-font-semibold">Order number:</h6>
                        <p>{{order.id}}</p>
                    </div>
                    <div class="tw-flex tw-flex-col">
                        <h6 class="tw-text-gray-700 tw-font-semibold">Total:</h6>
                        <p>{{totalPrice | currency}}</p>
                    </div>
                    <div class="tw-flex tw-flex-col">
                        <h6 class="tw-text-gray-700 tw-font-semibold">Date:</h6>
                        <p>{{order.timestamps.created_at | timestampToDateString}}</p>
                    </div>
                    <div class="tw-flex tw-flex-col">
                        <h6 class="tw-text-gray-700 tw-font-semibold">Status:</h6>
                        <p>{{order.order_status}}</p>
                    </div>
                </div>
            </div>

            <div class="tw-flex tw-flex-col tw-p-3 lg:tw-p-8 tw-space-y-2 lg:tw-space-y-4">
                <h5 class="tw-font-semibold tw-text-lg lg:tw-text-xl">Order Details</h5>
                <div v-for="line in order.lines" :key="line.id" class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-space-x-4 tw-w-9/12 lg:tw-w-8/12">
                        <div class="tw-w-8/12 lg:tw-w-5/12">
                            <p>{{line.book.title}}</p>
                        </div>
                        <div>
                            <p>x{{line.quantity}}</p>
                        </div>

                    </div>
                    <div class="tw-font-bold">
                        <p>{{ ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage))  | currency }}</p>
                    </div>
                </div>
                
            </div>
            <div class="tw-flex tw-flex-col tw-w-full tw-p-3 lg:tw-p-8 tw-space-y-2 lg:tw-space-y-4">
                <div class="tw-flex tw-justify-between tw-items-center">
                    <p>Subtotal:</p>
                    <p>{{subTotal | currency}}</p>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <p>Tax:</p>
                    <p>{{taxPrice | currency}}</p>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <p>Shipping:</p>
                    <p>{{1000 | currency}}</p>
                </div>
            </div>

            <div class="tw-flex tw-flex-col tw-w-full tw-p-3 lg:tw-p-8 tw-space-y-2 lg:tw-space-y-4">
                <div class="tw-flex tw-justify-between tw-items-center tw-font-bold">
                    <p>Total:</p>
                    <p>{{totalPrice | currency}}</p>
                </div>
                
            </div>

            <div class="tw-flex tw-flex-col tw-p-3 lg:tw-p-8 tw-space-y-2 lg:tw-space-y-4">
                <h5 class="tw-font-semibold tw-text-lg lg:tw-text-xl">Shipping Address</h5>
                <div class="tw-container tw-text-gray-900">
                    <div class="tw-flex tw-space-x-1">
                        <span>{{order.delivery_info.firstName}}</span><span>{{order.delivery_info.surname}}</span>
                    </div>
                    <p>{{order.delivery_info.address.street}} <br>
                        {{order.delivery_info.address.town}}, {{order.delivery_info.address.state}}
                    </p>
                    <!-- <p class="tw-bg-yellow-400"></p> -->

                </div>
                    
                
                
            </div>
                
        </section>
    </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState({ order: state => state.orders.order }),
        ...mapGetters({ totalPrice: "totalPrice", taxPrice: "taxPrice", subTotal: "subTotal", itemCount: "itemCount" })
    },
    async mounted() {
        await this.$store.dispatch("getOrderById", this.$route.params.id) 
    },
    // beforeDestroy() {

    // }
    
}
</script>