import { db } from '@/main';

export default {
    state: {
        message: null,
        loading: false,
        error: null,
    },
    getters: {
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value
        },
        setError(state, value) {
            state.error = value
        },
        setMessage(state, value) {
            state.message = value
        }
    },
    actions: {
        async submitMessage(context, item) {
            context.commit("setLoading", true)
            try {
                const id = db.collection("messages").doc().id
                item.id = id
                await db.collection("message").doc(id).set(item)
                context.commit("setMessage", "Your message has been successfully received, we will get back to you as soon as possible.")
                context.commit("setLoading", false)
               
            } catch(error) {
                context.commit("setError", error)
                context.commit("setLoading", false)
            }
        },
        async subscribe(context, email) {
            context.commit("setLoading", true)
            try {
                const id = db.collection("subscriptions").doc().id
                let item = {
                    email: email,
                    id: id,
                    created: Date.now()
                }
                await db.collection("subscriptions").doc(id).set(item)
                context.commit("setLoading", false)
                const response = {
                    success: true
                }
                return response
               
            } catch(error) {
                context.commit("setError", error)
                context.commit("setLoading", false)
                const response = {
                    success: false
                }
                return response
            }
        }
        
    }
}