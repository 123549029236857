<template>
    <div v-if="!loading">
        <div v-if="books.length > 0" class="tw-container tw-grid tw-grid-cols-12 tw-gap-6 tw-px-4 lg:tw-px-10 tw-py-4 lg:tw-py-24">
            <section class="tw-col-span-12 lg:tw-col-span-3">
                <v-expansion-panels :mandatory="$vuetify.breakpoint.mdAndUp" flat class="tw-border-black tw-border-t tw-border-r tw-border-l tw-rounded-none" accordion>
                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 lg:tw-text-lg tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <div>
                                    Categories
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            <div class="tw-flex tw-py-2 tw-items-center tw-justify-between" v-for="category in categories" :key="category.route">
                                <router-link class="tw-text-black black--text hover:tw-text-red-800" :to="category.route">{{category.text}}</router-link>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 lg:tw-text-lg tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <div>
                                    Filter by price
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            <div class="tw-flex tw-flex-col tw-space-y-3">
                                <v-range-slider v-model="filters.price" hide-details :min="0" :max="20000"></v-range-slider>
                                <div class="tw-flex tw-items-center tw-px-2 tw-justify-between">
                                    <p>{{filters.price[0] | currency}}</p>
                                    <p>{{filters.price[1] | currency}}</p>
                                </div>

                            </div>
                            
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>

            </section>
            <div class="tw-container tw-col-span-12 lg:tw-col-span-9 tw-p-4 lg:tw-px-4 lg:tw-py-2">
                <section class="tw-flex tw-flex-wrap tw-items-center tw-container tw-justify-between">
                    <h1 class="tw-text-transparent tw-w-12/12 lg:tw-w-4/12">Showing of {{filterBooks.length}} results</h1>
                    <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-gap-6 lg:tw-gap-0 tw-w-12/12 lg:tw-w-6/12 tw-space-x-0 lg:tw-space-x-8 tw-items-center tw-justify-between">
                        <div class="tw-container tw-w-12/12 lg:tw-w-3/12">
                            <v-select hide-details v-model="itemsPerPage" prefix="Show" :items="itemsPerPageArray"></v-select>
                        </div>
                        <div class="tw-container tw-w-12/12 lg:tw-w-5/12">
                            <v-select placeholder="Sort by" hide-details v-model="sortBy" :items="keys"></v-select>
                        </div>
                        
                        <div class="tw-container tw-hidden lg:tw-block tw-w-12/12 lg:tw-w-3/12">
                            <v-btn-toggle tile v-model="toggle_exclusive" mandatory>
                                <v-btn @click="toggle_exclusive = true">
                                    <v-icon>mdi-view-grid</v-icon>
                                </v-btn>
                                <v-btn @click="toggle_exclusive = false">
                                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                                </v-btn>
                            </v-btn-toggle>

                        </div>
                            
                    </div>

                </section>
                <section class="tw-container tw-mt-4 lg:tw-mt-0">
                    <v-data-iterator :items-per-page="itemsPerPage" :page.sync="page" @page-count="pageCount = $event" hide-default-footer :sort-desc="sortDesc" :sort-by="sortBy.toLowerCase()" no-data-text="Your search has not yielded any results." :items="filterBooks">
                        <template v-slot:default="{ items }">
                            <div v-if="!toggle_exclusive" class="lg:tw-py-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3">
                                <BookView v-for="item in items" :key="item.id" :item="item" />
                            </div>
                            <div v-else class="lg:tw-py-10 tw-grid tw-grid-cols-1">
                                <HorizontalBookView v-for="item in items" :key="item.id" :item="item" />
                            </div>
                        </template>

                    </v-data-iterator>
                    <v-pagination
                        class="tw-shadow-none"
                        v-model="page"
                        :length="pageCount"
                        :total-visible="6"
                    ></v-pagination>
                </section>
            </div>
        </div>

    </div>
    <div v-else class="tw-bg-white tw-space-y-2 lg:tw-space-y-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
        <div>
            <v-progress-circular color="primary" indeterminate size="80"></v-progress-circular>
        </div>
    </div>
    
</template>

<script>

import { mapState } from 'vuex'
import BookView from '../components/BookView.vue'
import HorizontalBookView from '../components/HorizontalBookView.vue'

export default {
    // mounted() {
    //     this.filters.category = this.$route.params.id
    //     (this.filters.category)
    // },
    components: {
        BookView,
        HorizontalBookView
    },
    async created() {
        await this.$store.dispatch("getBooks");

        //(this.$route)
    },
    computed: {
        ...mapState({ books: state => state.books.books, loading: state => state.books.loading }),
        filterBooks() {
            //const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
            //let id = capitalize(this.$route.params.id)
            let category = this.categories.find(category => category.route === this.$route.path);
            let categories = this.books.filter(book => book.quick_info.categories)
            let result = categories.filter(book => {
                return ((((1 - book.quick_info.percentage) * book.quick_info.retail_price)) >= this.filters.price[0]  && (((1 - book.quick_info.percentage) * book.quick_info.retail_price)) <= this.filters.price[1]) && book.quick_info.categories.includes(category.category);
            })
            return result
        },
        numberOfPages () {
            return Math.ceil(this.filterBooks.length / this.itemsPerPage)
        },
    },
    data() {
        return {
            toggle_exclusive: true,
            filters: {
                price: [0, 20000],
                category: null
            },
            pageCount: 0,
            page: 1,
            itemsPerPageArray: [9, 18, 27],
            itemsPerPage: 9,
            keys: [ "Title", "Price" ],
            sortBy: "title",
            sortDesc: false,
            categories: [
                {
                    text: "Fiction",
                    route: "/category/fiction",
                    category: "Fiction"
                },
                {
                    text: "Non Fiction",
                    route: "/category/non-fiction",
                    category: "Non-fiction"
                },
                {
                    text: "Health",
                    route: "/category/health",
                    category: "Health"
                },
                {
                    text: "Children",
                    route: "/category/children",
                    category: "Children"
                },
                {
                    text: "Young Adult",
                    route: "/category/youngadult",
                    category: "Young Adult"
                },
                // {
                //     text: "Juvenile Fiction",
                //     route: "/category/juvenilefiction",
                //     category: "Juvenile Fiction"
                // },
                {
                    text: "Parenting",
                    route: "/category/parenting",
                    category: "Parenting"
                },
                {
                    text: "Religion",
                    route: "/category/religion",
                    category: "Religion"
                },
                {
                    text: "Business & Economics",
                    route: "/category/business&economics",
                    category: "Business & Economics"

                },
                {
                    text: "Self Help",
                    route: "/category/self-help",
                    category: "Self-Help"
                },
                {
                    text: "Literature",
                    route: "/category/literature",
                    category: "literature"
                },
                
                
                
                

                
                // {
                //     text: "Summer Reading",
                //     route: "/category/summer-reading"
                // },
                // {
                //     text: "Teens & YA",
                //     route: "/category/teens-ya"
                // },    
                
            ],
            // categories: [
            //     {
            //         text: "Nigeria",
            //         route: "/category/nigeria"
            //     },
            //     {
            //         text: "Fiction",
            //         route: "/category/fiction"
            //     },
            //     {
            //         text: "Nonfiction",
            //         route: "/category/nonfiction"
            //     },
            //     {
            //         text: "Kids",
            //         route: "/category/kids"
            //     },
            //     {
            //         text: "Summer Reading",
            //         route: "/category/summer-reading"
            //     },
            //     {
            //         text: "Teens & YA",
            //         route: "/category/teens-ya"
            //     },    
                
            // ]
        }
    },
    methods: {
        nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
    }
}
</script>