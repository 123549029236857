<template>
    <div class="tw-container tw-space-y-12 tw-flex tw-bg-pink-50 tw-flex-col tw-items-center tw-w-full tw-py-4 tw-px-2 md:tw-py-12 md:tw-px-10 md:tw-max-w-full tw-mx-auto">
        <h1 class="tw-font-bold tw-text-2xl md:tw-text-3xl lg:tw-text-4xl">Checkout</h1>
        <section class="tw-container tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-4 lg:tw-gap-6">
            <div class="tw-col-span-1 tw-border tw-bg-white tw-border-gray-300 lg:tw-col-span-3 tw-w-full tw-p-4 md:tw-p-8">
                <h3 class="tw-text-lg tw-mb-10 md:tw-text-xl">Shipping details</h3>
                <validation-observer
                    ref="observer"
                >

                    <v-form ref="form">
                        <div class="tw-container tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                rules="required|max:10"
                            >
                                <v-text-field v-model="form.firstName" :error-messages="errors" outlined label="First name" class="tw-rounded-none"></v-text-field>
                            </validation-provider>
                            <validation-provider 
                                v-slot="{ errors }"
                                name="Surname"
                                rules="required|max:10"
                            >
                                <v-text-field v-model="form.surname" :error-messages="errors" required outlined label="Surname" class="tw-rounded-none"></v-text-field>
                            </validation-provider>
                            
                        </div>

                        <validation-provider 
                            v-slot="{ errors }"
                            name="Address"
                            rules="required"
                        >
                            <v-text-field v-model="form.address.street" :error-messages="errors" outlined label="Street address" placeholder="House number and street name" class="tw-rounded-none"></v-text-field>
                        </validation-provider>

                        
                        <v-text-field v-model="form.address.unit" persistent-hint hint="optional" outlined placeholder="Apartment, suite, unit etc" class="tw-rounded-none"></v-text-field>
                        <!-- <v-text-field outlined label="Street address" placeholder="House number and street name" class="tw-rounded-none"></v-text-field> -->
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Town"
                            rules="required"
                        >
                            <v-text-field :error-messages="errors" v-model="form.address.town" outlined label="Town/District" placeholder="District" class="tw-rounded-none"></v-text-field>
                        </validation-provider>

                        <validation-provider 
                            v-slot="{ errors }"
                            name="State"
                            rules="required"
                        >
                            <v-select :items="states" outlined :error-messages="errors" v-model="form.address.state" label="State" class="tw-rounded-none" required></v-select>
                        </validation-provider>
                        
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email"
                        >
                            <v-text-field :error-messages="errors" v-model="form.email" outlined label="Email" class="tw-rounded-none"></v-text-field>
                        </validation-provider>

                        <validation-provider 
                            v-slot="{ errors }"
                            name="phoneNumber"
                            :rules="{
                                required: true,
                                digits: 11,
                                regex: '^(080)\\d{8}$'
                            }"
                        >
                            <v-text-field :error-messages="errors" v-model="form.phoneNumber" outlined label="Phone" class="tw-rounded-none"></v-text-field>
                        </validation-provider>

                        <div class="tw-flex tw-flex-col tw-space-y-2">
                            <h3 class="tw-text-lg tw-mb-6 md:tw-text-xl">Additional infomation</h3>
                            <h6>Order notes (optional)</h6>
                            <v-textarea class="tw-rounded-none" outlined></v-textarea>
                        </div>
                            
                    </v-form>
  
                </validation-observer>
                    
            </div>
            <div class="tw-col-span-1 lg:tw-col-span-2 tw-w-full">
                <v-expansion-panels mandatory flat class="tw-border-black tw-border-t tw-border-r tw-border-l tw-rounded-none" accordion>
                    
                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-gray-300"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <div>
                                    Your order
                                </div>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            <div class="tw-flex tw-py-2 tw-items-center tw-justify-between" v-for="line in lines" :key="line.id">
                                <h1>{{line.book.title}}  x  {{line.quantity}}</h1>
                                <div>
                                    {{ ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage))  | currency }}
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-gray-300"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <div>
                                    Cart Totals
                                </div>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-black">
                            <div class="tw-flex tw-py-2 tw-items-center tw-justify-between">
                                <h1>Subtotal</h1>
                                <div>
                                    {{ subTotal  | currency }}
                                </div>
                            </div>
                            <div class="tw-flex tw-py-2 tw-items-center tw-justify-between">
                                <h1>Tax</h1>
                                <div>
                                    {{ taxPrice  | currency }}
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-gray-300"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                                <h1>Payment</h1>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-gray-300">
                            <v-radio-group v-model="payment" mandatory>
                                <!-- {{shipping}} -->
                                <div class="tw-flex tw-flex-col tw-space-y-2">
                                    <v-radio label="Bank Transfer" value="bank_transfer"></v-radio>
                                    <h6 class="tw-px-2 lg:tw-px-4">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</h6>
                                </div>
                                <!-- <div class="tw-flex tw-items-start tw-mt-4 tw-justify-between">
                                    <v-radio label="Express" value="1000">Express</v-radio>
                                    <h6>{{1000 | currency}}</h6>
                                </div> -->
                                
                                
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    
                    <v-expansion-panel
                        class="tw-p-0 tw-border-b tw-border-gray-300"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                                <h1>Shipping</h1>
                            </div>
                            <!-- {{item.title}} -->
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="tw-text-gray-300">
                            <v-radio-group v-model="shipping" mandatory>
                                <!-- {{shipping}} -->
                                <div class="tw-flex tw-items-start tw-justify-between">
                                    <v-radio label="Standard" value="400">Standard</v-radio>
                                    <h6>{{400 | currency}}</h6>
                                </div>
                                <div class="tw-flex tw-items-start tw-mt-4 tw-justify-between">
                                    <v-radio label="Express" value="1000">Express</v-radio>
                                    <h6>{{1000 | currency}}</h6>
                                </div>
                                
                                <!-- <v-radio label="Express" value="2">Express</v-radio> -->
                            </v-radio-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        disabled
                        class="tw-p-0 tw-border-b tw-border-black"
                    >
                        <v-expansion-panel-header class="tw-text-gray-800 tw-font-bold">
                            <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
                                <!-- <v-icon color="black">{{item.icon}}</v-icon> -->
                                <p>Total</p>
                                <p>{{totalPrice + Number(shipping) | currency}}</p>
                            </div>
                        </v-expansion-panel-header>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="tw-mt-10">
                    <v-btn :disabled="loading" :loading="loading" @click="submitForm" class="tw-normal-case tw-h-full" block x-large depressed tile color="primary">Place Order</v-btn>
                </div>
            </div>

        </section>
            
        

    </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

//   extend('regex', {
//     ...regex,
//     message: '{_field_} {_value_} does not match {regex}',
//   })

  extend('regex', {
    ...regex,
    message: 'Please enter a valid phone number',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
        ...mapState({ lines: state => state.shoppingcart.lines, orderId: state => state.orders.orderNumber, loading: state => state.orders.loading }),
        ...mapGetters({ totalPrice: "shoppingcart/totalPrice", taxPrice: "shoppingcart/taxPrice", subTotal: "shoppingcart/subTotal", itemCount: "shoppingcart/itemCount" })
    },
    data() {
        return {
            isFormValid: false,
            payment: "bank_transfer",
            shipping: 400,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            otherRules: [
                v => !!v || 'Required',
            ],
            form: {
                firstName: '',
                surname: '',
                address: {
                    street: '',
                    unit: '',
                    town: '',
                    state: null
                },
                email: '',
                phoneNumber: ''
            },
            states: [
                "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bayelsa", "Bauchi", "Benue", "Borno", "Cross River", "Delta", "Ebonyi",
                "Edo", "Enugu", "Ekiti", "F.C.T", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kwara", "Kogi", "Lagos", "Nasarawa", "Niger",
                "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara"
            ],
        }
    },
    methods: {
        ...mapActions({
            clearCart: "shoppingcart/clearCartData"
        }),
        async submitForm() {
            const result = await this.$refs.observer.validate()
            
            if(result) {
                let order = {
                    delivery_info: this.form,
                    lines: this.lines,
                    shipping: this.shipping,
                    payment: this.payment,
                    timestamps: {
                        created_at: Date.now()
                    },
                    payment_status: {
                        received: false,
                        received_at: false
                    },
                    status: {
                        ready: false,
                        ready_at: null,
                        delivered: false,
                        delivered_at: null,
                        picked_up: false,
                        picked_up_at: null
                    },
                    order_status: "Awaiting Payment"
                }

                await this.$store.dispatch("addOrder", order)
                    
                this.resetForm()

                await this.$router.push({ name: 'OrderReceived', params: { id: this.orderId } })

                this.clearCart()

            }
            // this.$refs.observer.validate().then(result => {
            //     if(result) {
            //         let order = {
            //             delivery_info: this.form,
            //             lines: this.lines,
            //             shipping: this.shipping,
            //             payment: this.payment
            //         }
            //         (order)
            //         this.resetForm()
            //     }
            // })
        },
        resetForm() {
            this.form = {
                firstName: '',
                surname: '',
                address: {
                    street: '',
                    unit: '',
                    town: '',
                    state: null
                },
                email: '',
                phoneNumber: ''
            }
            this.$refs.observer.reset()
        }
    }
}
</script>