<template>
    <div>
    <v-menu ref="searchMenuRef" max-height="400" v-model="searchMenuVisibility" :close-on-content-click="true"  nudge-bottom="10" transition="scale-transition" offset-y min-width="290px">
        <v-text-field class="tw-rounded-none" flat @keyup="search" outlined dense slot="activator" v-model="searchQuery" label="Search by Title" append-icon="mdi-magnify" single-line hide-details ></v-text-field>
        <div>
            <v-list width="500px" min-width="290px" dense flat>
                <v-list-item @click="goToDetail(item.objectID)" v-for="item in hits" :key="item.objectID">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.title}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
            </v-list>
        </div>
        <div class="tw-bg-white tw-p-2" v-if="searchQuery.length > 2 && hits.length == 0">
            <p>No results found</p>
        </div>
        <!-- <div class="tw-flex tw-flex-col tw-bg-red-400">
            <ais-instant-search index-name="books" :search-client="searchClient">
                <ais-index index-name="books" :query="searchQuery">
                    <v-list width="500px" min-width="290px" color="red" dense flat>
                        <ais-hits>
                            <template v-slot:item="{ item }">
                                <v-list-item :to="'/book/'+item.objectID">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{item.title}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    
                                </v-list-item>

                            </template>
                        </ais-hits>
                    </v-list>
                </ais-index>
                
                    

                

            </ais-instant-search>
                
        </div> -->
        
        
    </v-menu>
    </div>
    
</template>


<script>
//import credentials from '@/../config/credentials';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
export default {
  name: 'SearchBox',
  props:[],
  data() {
    return {
      searchQuery:"",
      searchMenuVisibility:false,
    //   searchClient: algoliasearch(
    //       "YBUIIOH9NL",
    //       "32d02e4d0f53c07dc8f6e0fa8028f1ea",
    //   ),
      hits: [],
      index: null,
      searchClient: null,

    };
  },
  mounted() {
      this.searchClient = algoliasearch(
          "YBUIIOH9NL",
          "32d02e4d0f53c07dc8f6e0fa8028f1ea"
      )
      this.index = this.searchClient.initIndex("books")
  },
  computed:{
      algoliaCredentials(){
          return {
            appId:"YBUIIOH9NL",
            apiKey:"32d02e4d0f53c07dc8f6e0fa8028f1ea",
            indexName:'books',
        }
      }
  },
  watch: {
      searchQuery(val) {
          if(!val) {
            this.searchMenuVisibility = false
          } else {
              this.searchMenuVisibility = true
          }
      }
  },
  methods:{
      printLog(value) {
          (value)
      },
      async search() {
        let query = this.searchQuery
        this.index.search(query).then(({ hits }) => {
            this.hits = hits
        })
      },
      goToDetail(id) {
          this.$store.dispatch("selectID", id)
          this.$router.push({ name: "BookDetail", params: { id: id } })
          this.searchQuery = ""
      }
  },
}
</script>

<style>
.ais-index {background-color:white;}
.ais-index em {background-color:rgb(236, 230, 178);}
</style>