import { db } from '@/main';

export default {
    state: {
        selectedBook: {},
        books: [],
        loading: false,
        items: [],
        popular: [],
        bestsellers: [],
        kids: [],
        nigerian: [],
        fiction: [],
        non_fiction: [],
        young_adults: [],
        new_releases: [],
        id: null,
        related: []
    },
    getters: {
        getBookById: state => (id) => {
            return state.books.find(book => book.quick_info.id === id)
        },
    },
    mutations: {
        setBook(state, value) {
            state.selectedBook = value
        },
        setID(state, value) {
            state.id = value
        },
        setRelated(state, value) {
            state.related = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setBooks(state, value) {
            state.books = value
        },
        setItems(state, value) {
            state.items = value
        },
        setPopular(state, value) {
            state.popular = value
        },
        setBestSellers(state, value) {
            state.bestsellers = value
        },
        setKids(state, value) {
            state.kids = value
        },
        setYoungAdults(state, value) {
            state.young_adults = value
        },
        setNigerian(state, value) {
            state.nigerian = value
        },
        setFiction(state, value) {
            state.fiction = value
        },
        setNonFiction(state, value) {
            state.non_fiction = value
        },
        setNewReleases(state, value) {
            state.new_releases = value
        },

    },
    actions: {
        selectBook(context, item) {
            context.commit("setBook", item)
        },
        selectID(context, item) {
            context.commit("setID", item)
        },
        async getBooks(context) {
            try {
                // let collection = await db.collection("menuitems").get()
                // let books = new Array()
                // collection.forEach(doc => {
                //     let book = doc.data()
                    
                //     book.quick_info = {
                //         id: doc.id,
                //         title: book.title,
                //         authors: book.volumeInfo.authors,
                //         isDiscounted: book.isDiscounted,
                //         percentage: Number(book.percentage),
                //         retail_price: Number(book.retail_price),
                //         in_stock: Number(book.in_stock),
                //         imageLinks: book.volumeInfo.imageLinks
                //     }

                //         //categories: book.volumeInfo.categories,

                //     if (book.volumeInfo.categories) {
                //         book.quick_info.categories = book.volumeInfo.categories
                //     } else {
                //         book.quick_info.categories = null
                //     }

                //     books.push(book)
                // })

                // commit("setItems", books)
                context.commit("setLoading", true)
                await db.collection("menuitems").onSnapshot(async function(querySnapshot) {
                    let books = new Array()
                    querySnapshot.forEach(doc => {
                        let book = doc.data()
                        book.quick_info = {
                            id: doc.id,
                            title: book.title,
                            authors: book.volumeInfo.authors,
                            isDiscounted: book.isDiscounted,
                            percentage: Number(book.percentage),
                            retail_price: Number(book.retail_price),
                            in_stock: Number(book.in_stock),
                            imageLinks: book.volumeInfo.imageLinks
                        }

                        

                        if (book.volumeInfo.categories) {
                            book.quick_info.categories = book.volumeInfo.categories
                        } else {
                            book.quick_info.categories = null
                        }

                        books.push(book)
                    })
                    context.commit("setBooks", books);
                    context.commit("setLoading", false)
                })
            } catch(error) {
                context.commit("setLoading", false)
                return error
            }
        },
        async getHomePage(context) {
            context.commit("setLoading", true)
            try {
                await db.collection("website").onSnapshot(async function(querySnapshot) {
                    let books = new Array()
                    querySnapshot.forEach(doc => {
                        let book = doc.data()
                        // book.quick_info = {
                        //     id: doc.id,
                        //     title: book.title,
                        //     authors: book.volumeInfo.authors,
                        //     isDiscounted: book.isDiscounted,
                        //     percentage: Number(book.percentage),
                        //     retail_price: Number(book.retail_price),
                        //     in_stock: Number(book.in_stock),
                        //     imageLinks: book.volumeInfo.imageLinks
                        // }

                        // if (book.volumeInfo.categories) {
                        //     book.quick_info.categories = book.volumeInfo.categories
                        // } else {
                        //     book.quick_info.categories = null
                        // }

                        book.quick_info.imageLinks = book.volumeInfo.imageLinks;

                        books.push(book)
                    })
                    let kids = books.filter(book => book.tag === "children")
                    context.commit("setKids", kids);
                    let popular = books.filter(book => book.tag === "trending")
                    context.commit("setPopular", popular);
                    let bestsellers = books.filter(book => book.tag === "best-sellers")
                    context.commit("setBestSellers", bestsellers);
                    let nigerian = books.filter(book => book.tag === "nigerian-authors")
                    context.commit("setNigerian", nigerian);
                    let young_adults = books.filter(book => book.tag === "juvenile-fiction")
                    context.commit("setYoungAdults", young_adults);
                    let fiction = books.filter(book => book.tag === "fiction")
                    context.commit("setFiction", fiction);
                    let non_fiction = books.filter(book => book.tag === "non-fiction")
                    context.commit("setNonFiction", non_fiction);
                    let new_releases = books.filter(book => book.tag === "new-releases")
                    context.commit("setNewReleases", new_releases);

                    context.commit("setLoading", false)
                })
            } catch(error) {
                context.commit("setLoading", false)
                return error
            }
        },
        async fetchBookById({commit, state}, id) {
            try {
                commit("setLoading", true)
                const doc = await db.collection("menuitems").doc(id).get()
                if (doc.exists) {
                    let book = doc.data()
                    book.quick_info = {
                        id: doc.id,
                        title: book.title,
                        authors: book.volumeInfo.authors,
                        isDiscounted: book.isDiscounted,
                        percentage: Number(book.percentage),
                        retail_price: Number(book.retail_price),
                        in_stock: Number(book.in_stock),
                        imageLinks: book.volumeInfo.imageLinks
                    }

                    if (book.volumeInfo.categories) {
                        book.quick_info.categories = book.volumeInfo.categories
                    } else {
                        book.quick_info.categories = null
                    }
                    let category = book.quick_info.categories[0]
                    let categories = state.books.filter(book => book.quick_info.categories)
                    let related = categories.filter(book => book.quick_info.categories.includes(category)).slice(0, 5)
                    commit("setRelated", related)
                    commit("setBook", book)
                    commit("setLoading", false)
                } else {
                    commit("setLoading", false)
                }
            } catch(error) {
                commit("setLoading", false)
                return error
            }
        }
    }
}