<template>
    <v-card flat tile elevation="0">
        <div class="tw-grid tw-grid-cols-3 tw-gap-4 py-5">
            <div class="tw-flex tw-flex-row tw-w-full">
                <img style="max-width:150px;" :src="line.book.src" class="tw-object-cover tw-h-40 tw-align-middle" alt="">
            </div>
            <div class="tw-col-span-2 tw-py-1 tw-px-4 tw-flex-col tw-flex tw-space-y-3">
                <div>
                    {{line.book.title}}
                </div>
                <div class="tw-text-sm">
                    by {{line.book.author}}
                </div>
                <div class="tw-text-sm tw-flex tw-flex-row tw-flex-wrap tw-space-x-3">
                    <div>
                        {{ ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage))  | currency }}
                    </div>
                    <div v-if="line.book.discount" class="tw-line-through">
                        {{ ((line.quantity * line.book.price))  | currency }}
                    </div>
                    
                </div>
                <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-3/5 tw-border-2">
                    <v-btn color="white" text icon @click="handleDecrement" :disabled="disableDecrement">
                        <v-icon color="primary-800">mdi-minus</v-icon>
                    </v-btn>
                    <div>
                        {{qvalue}}
                    </div>
                    <v-btn color="white" icon text @click="handleIncrement">
                        <v-icon color="primary-800">mdi-plus</v-icon>
                    </v-btn>
                </div>
                <!-- <div class="tw-w-2/5">
                    <v-select
                        :items="items"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :value="qvalue"
                        :return-object="true"
                        v-on:change="sendChange"
                        dense
                        hide-details
                        outlined
                    ></v-select>
                </div> -->
                <div>
                    <v-btn small class="red--text tw-normal-case" text tile depressed @click="sendRemoveEvent">
                        Remove
                    </v-btn>
                </div>
                
            </div>
        </div>
        <v-divider class="text-center"></v-divider>
        <!-- <v-row no-gutters>
            <v-col cols="2">
                <v-select
                    :items="items"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :value="qvalue"
                    :return-object="true"
                    v-on:change="sendChange"
                    dense
                ></v-select>
            </v-col>
            <v-col cols="6">
                <v-card-text class="font-weight-medium">{{ line.book.title }}</v-card-text>
                <v-card-actions class="mt-n5">
                    <v-btn small class="red--text" text tile depressed @click="sendRemoveEvent">
                        Remove
                    </v-btn>
                    <v-btn small class="red--text" text tile depressed @click="sendRemoveEvent">
                        Edit
                    </v-btn>
                </v-card-actions>
            </v-col>
            <v-col cols="4">
                <v-card-text class="text-right">{{ (line.quantity * line.book.price)  | currency }}</v-card-text>
            </v-col>
        </v-row>
        
        <v-divider class="text-center"></v-divider> -->
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ["line"],
    data: function() {
        return {
            disableDecrement: false,
            qvalue: this.line.quantity,
            items: [{text: 1, value: 1}, {text: 2, value: 2}, {text: 3, value: 3}, {text: 4, value: 4}, {text: 5, value: 5}, {text: 6, value: 6}, {text: 7, value: 7}, {text: 8, value: 8}, {text: 9, value: 9}, {text: 10, value: 10}]
        }
    },
    computed: {
        ...mapGetters({getFrenchState: 'getFrenchState'})
    },
    watch: {
        qvalue(newVal) {
            if (newVal == 1) {
                this.disableDecrement = true
            } else {
                this.disableDecrement = false
            }
        }
    },
    created() {
        this.disableDecrement = this.line.quantity == 1 ? true : false
    },
    methods: {
        sendChange(q) {
            if (q.value > 0) {
                this.$emit("quantity", Number(q.value));
                this.qvalue = q.value
            } else {
                this.$emit("quantity", 1);
                this.qvalue = 1;
                q.value = this.qvalue;
            }
        },
        handleIncrement() {
            this.qvalue++
            this.$emit("quantity", this.qvalue)
        },
        handleDecrement() {
            this.qvalue--
            this.$emit("quantity", this.qvalue)
        },
        sendChangeEvent($event) {
            if ($event.target.value > 0) {
                this.$emit("quantity", Number($event.target.value));
                this.qvalue = $event.target.value;
            } else {
                this.$emit("quantity", 1);
                this.qvalue = 1;
                $event.target.value = this.qvalue;
            }
        },
        sendRemoveEvent() {
            this.$emit("remove", this.line);
        }
    }
}
</script>