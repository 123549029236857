<template>
    <div id="map" style='width: 100%; height: 100%;'>
        <!-- <v-card class="red" flat width="100" height="100" style="height:100px;width:30%"></v-card> -->
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
    data() {
        return {
            map: null,
            marker: null
        }
    },
    props: ["coords"],
    mounted() {
        mapboxgl.accessToken = 'pk.eyJ1IjoiYmFnbzE5IiwiYSI6ImNrMWo5ODM1YzF5aW8zYnMxcmtpMDRldW4ifQ.ylMZt_5l-u4MHl6gZv20Ww';
        const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
        //style: 'mapbox://styles/carbondesignsystem/ck7c8ccac08jj1imhvd2g4qfb',
        //center: [-74.5, 40], // starting position [lng, lat]
        center: this.coords,
        zoom: 16, // starting zoom
        interactive: false

        });
        var marker = new mapboxgl.Marker()
            .setLngLat(this.coords)
            .addTo(map);
        map.scrollZoom.disable()
        this.map = map
        this.marker = marker
    }
}
</script>