<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-border-b tw-py-4 md:tw-pb-12">
    <section>
      <div class="tw-pb-4 md:tw-p-12">
        <div class="tw-font-bold tw-text-lg md:tw-text-4xl">
          <h1>Contact Us</h1>
        </div>
      </div>
    </section>
    <section class="">
      <div class="tw-container tw-relative tw-mx-auto">
        <div style="height:480px;">
            <Map :coords="[7.466518854963176, 9.061001293498633]" />
        </div>
        
        <div class="tw-grid tw-grid-cols-12 tw-relative">
          <div class="tw-col-span-12 lg:tw-col-span-8 tw-col-start-1 lg:tw-col-start-3">
            <div class="tw-bg-white tw-mt-0 tw-pt-4 tw-pb-4 tw-px-4 lg:tw--mt-24 lg:tw-px-24 lg:tw-pt-16 lg:tw-pb-8 tw-w-full tw-z-0">
              <div>
                <h6 class="tw-font-bold tw-text-lg lg:tw-text-4xl tw-mb-2 lg:tw-mb-8">Contact information</h6>
                <p>
                  We will answer any questions you may have about our online sales, rights or partnership service right here.
                </p>
              </div>
            </div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4 lg:tw-px-24">
              <div class="tw-flex tw-flex-col tw-space-y-2 lg:tw-space-y-4">
                <h6 class="tw-mb-2 tw-font-bold tw-text-base lg:tw-text-xl">Abuja Location</h6>
                <div class="tw-text-gray-500">
                    <div>Machima Plaza</div>
                    <div>No 2 Mambolo Close Off Sultan Abubakar Way</div>
                    <div>Wuse Zone 3, Abuja, Nigeria</div>
                    <!-- <div>Abuja, Nigeria</div> -->
                </div>

                <div class="tw-flex tw-flex-col tw-space-y-1 tw-text-gray-500">
                    <div>help@theadamspages.com</div>
                    <div>+2348148647822</div>
                </div>

                <div>
                    <h6 class="tw-mb-6 tw-font-bold tw-text-base lg:tw-text-xl">Social Media</h6>

                    <div class="tw-flex tw-space-x-3">
                        <div>
                            <v-btn target="_blank" href="https://www.instagram.com/adamspages/" fab x-small outlined>
                                <v-icon>mdi-instagram</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn href="https://www.instagram.com/adamspages/" target="_blank" fab x-small outlined>
                                <v-icon>mdi-facebook</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn fab x-small outlined>
                                <v-icon>mdi-twitter</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn fab x-small outlined>
                                <v-icon>mdi-linkedin</v-icon>
                            </v-btn>
                        </div>
                    </div>

                </div>

                    
              </div>
              
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="tw-mt-6 tw-w-screen">
        <div class="tw-container tw-mx-auto tw-relative">
            <div class="tw-grid tw-grid-cols-12 tw-relative">
                <div class="tw-col-span-12 tw-col-start-1 lg:tw-col-span-8 lg:tw-col-start-3">
                    <div class="tw-px-4 lg:tw-px-24 tw-pt-4 tw-pb-0 lg:tw-pt-16 lg:tw-pb-8 tw-w-full">
                        <div class="tw-container">
                            <h6 class="tw-font-bold tw-text-lg lg:tw-text-4xl tw-mb-0 lg:tw-mb-0">Get in touch</h6>
                        </div>
                        
                    </div>
                    <div v-if="message || error" class="tw-px-4 lg:tw-px-24 tw-pt-4 tw-pb-0 lg:tw-pt-2 lg:tw-pb-2 tw-w-full">
                        <v-alert v-if="error" type="error" text>{{ error }}</v-alert>
                        <v-alert v-else-if="message" type="success" text>{{ message }}</v-alert>
                    </div>
                    <validation-observer
                        ref="observer"
                    >
                        <v-form ref="form" @submit.prevent="submit">
                            <div class="tw-grid tw-w-full tw-px-4 lg:tw-px-24 tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-2">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Name"
                                        rules="required|max:10"
                                    >
                                        <v-text-field v-model="form.name" :error-messages="errors" class="tw-rounded-none" label="Name" outlined></v-text-field>
                                    
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Email"
                                        rules="required|email"
                                    >
                                        <v-text-field v-model="form.email" :error-messages="errors" class="tw-rounded-none" label="Email" outlined></v-text-field>
                                    
                                    </validation-provider>
                                
                            </div>
                            <div class="tw-grid tw-w-full tw-px-4 lg:tw-px-24 tw-grid-cols-1 tw-gap-4">
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Subject"
                                        rules="required|"
                                    >
                                        <v-text-field v-model="form.subject" :error-messages="errors" class="tw-rounded-none" label="Subject" outlined></v-text-field>
                                    
                                    </validation-provider>    
                            </div>
                            <div class="tw-grid tw-w-full tw-px-4 lg:tw-px-24 tw-grid-cols-1 tw-gap-4">
                                <v-textarea v-model="form.message" class="tw-rounded-none" label="Message" outlined></v-textarea>
                                
                            </div>
                            <div class="tw-grid tw-w-full tw-px-4 lg:tw-px-24 tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                <v-btn :disabled="loading" :loading="loading" type="submit" tile block class="primary tw-normal-case" depressed x-large>Submit Message</v-btn>    
                            </div>

                        </v-form>
                    
                    
                    </validation-observer>
                            

                </div>

            </div>

        </div>
    </section>
  </div>
</template>

<script>

import Map from "../components/MapView.vue"
import { mapState } from 'vuex'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')


  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

export default {
    name: "ContactView",
    components: {
        Map,
        ValidationProvider,
        ValidationObserver
    },
    computed: {
        ...mapState({loading: state => state.contact.loading, message: state => state.contact.message, error: state => state.contact.error })
    },
    methods: {
        async submit() {
            await this.$store.dispatch("submitMessage", this.form)

            if(this.message) {
                this.resetForm()
            }
        },
        resetForm() {
            this.form = {
                name: '',
                email: '',
                subject: '',
                message: '',
                timestamp: {
                    created_at: Date.now()
                }
            }
            this.$refs.observer.reset()
        }
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: '',
                timestamp: {
                    created_at: Date.now()
                }
            },
            settings: {
                "dots": true,
                "infinite": false,
                "arrows": true,
                "speed": 500,
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "initialSlide": 0,
                "responsive": [
                {
                    "breakpoint": 1024,
                    "settings": {
                    "slidesToShow": 2,
                    "slidesToScroll": 2,
                    "infinite": true,
                    "dots": true
                    }
                },
                {
                    "breakpoint": 600,
                    "settings": {
                    "slidesToShow": 2,
                    "slidesToScroll": 2,
                    "initialSlide": 2
                    }
                },
                {
                    "breakpoint": 480,
                    "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1
                    }
                }
                ]
            }
        }
    }
  
}
</script>

<style>
button.slick-prev:before, button.slick-next:before {
  color: #5D1049 !important;
}
</style>