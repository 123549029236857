import { db } from '@/main';

export default {
    state: {
        items: [],
        loading: false,
        orderNumber: null,
        taxRate: 0.075,
        order: {
            lines: [],
            timestamps: {
                created_at: null
            }
        },
        error: null
    },
    getters: {
        itemCount: state => state.order.lines.reduce((total, line) =>
            total + line.quantity, 0),
        taxPrice: state => state.order.lines.reduce((total, line) => 
            total + ((((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) * state.taxRate)), 0),
        subTotal: state => state.order.lines.reduce((total, line) =>
            total + ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)), 0),
        totalPrice: state => state.order.lines.reduce((total, line) =>
            total + ((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) + (((line.quantity * line.book.price) - (line.quantity * line.book.price * line.book.percentage)) * state.taxRate), 0),

    },
    mutations: {
        setOrderItems(state, value) {
            state.items = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setOrderNumber(state, value) {
            state.orderNumber = value
        },
        setReceivedOrder(state, value) {
            state.order = value
        },
        setError(state, value) {
            state.error = value
        }
    },
    actions: {
        async getOrderItems({commit}) {
            //const uid = auth.currentUser
            try {
                commit("setLoading", true)
                await db.collection("orders").onSnapshot(async function(querySnapshot) {
                    let inventory = new Array()
                    querySnapshot.forEach(doc => {
                        inventory.push(doc.data())
                    })
                    commit("setOrderItems", inventory)
                    commit("setLoading", false)
                })
            } catch(error) {
                
                commit("setLoading", false)
                return error
            }
        },
        async addOrderItem(context, item) {
            context.commit("setLoading", true)
            const id = db.collection("orderItems").doc().id
            const purchase_date = Date.now()
            try {
                item.id = id
                item.purchase_date = purchase_date
                await db.collection("orderItems").doc(id).set(item)
                context.commit("setLoading", false)
                //(item)
            } catch(error) {
                
                context.commit("setLoading", false)
                return error
            }
        },
        async addOrder(context, item) {
            context.commit("setLoading", true)
            try {
                const id = db.collection("orders").doc().id
                item.id = id
                await db.collection("orders").doc(id).set(item)
                context.commit("setOrderNumber", id)
                context.commit("setReceivedOrder", item)
                context.commit("setLoading", false)
                //(item)
            } catch(error) {
                
                context.commit("setLoading", false)
                return error
            }
        },
        async updateOrder(context, data) {
            context.commit("setLoading", true)
            try {
                await db.collection("orders").doc(data.id).set( { lines: data.lines }, { merge: true })
                context.commit("setLoading", false)
                //(item)
            } catch(error) {
                context.commit("setError", error)
                context.commit("setLoading", false)
            }
        },
        async getOrderById(context, id) {
            context.commit("setError", null)
            context.commit("setLoading", false)
            try {
                let doc = await db.collection("orders").doc(id).get()

                if(doc.exists) {
                    context.commit("setReceivedOrder", doc.data())
                    context.commit("setLoading", false)
                } else {
                    throw "We don't have any orders associated with that number in our system. Please double check the number and try again."
                }
            } catch(error) {
                
                context.commit("setError", error)
                context.commit("setLoading", false)
            }
        }
    }
}