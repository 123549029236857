<template>
    <div class="tw-py-3 tw-overflow-x-auto">
      <div class="tw-px-4 md:tw-px-12 tw-text-xl tw-inline-flex tw-space-x-0">

        <v-card @click="goToDetail(item)" flat tile hover outlined class="tw-w-72 tw-h-96 hover:tw-border-black hover:tw-shadow-lg" v-for="(item, i) in items" :key="i">
          <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-space-y-8 tw-p-4">
            <div class="tw-self-center">
              <v-img style="max-width:150px;" :src="item.quick_info.imageLinks.thumbnail" :lazy-src="item.quick_info.imageLinks.thumbnail" class="tw-object-cover tw-h-52 tw-align-middle" alt=""></v-img>
              <!-- <img style="max-width:150px;" :src="item.quick_info.src" class="tw-object-cover tw-h-52 tw-align-middle" alt=""> -->
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-1">
              <h2 class="grey--text text--darken-4 tw-text-base tw-font-bold tw-no-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden">
                {{item.quick_info.title}}
              </h2>
              <div>
                <a style="max-width:150px" class="grey--text text--darken-4 tw-text-xs tw-no-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden" href="#">
                  {{item.quick_info.authors[0]}}
                </a>
              </div>
              <div v-if="item.quick_info.isDiscounted" class="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                  <span class="tw-font-bold tw-text-sm">
                      {{(item.quick_info.retail_price * (1-item.quick_info.percentage)) | currency}}
                  </span>
                  <span class="tw-font-bold tw-text-sm tw-line-through tw-text-gray-400">
                      {{item.quick_info.retail_price | currency}}
                  </span>
              </div>
              <div v-else class="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                  <span class="tw-font-bold tw-text-sm">
                      {{item.quick_info.retail_price | currency}}
                  </span>
              </div>
              
              

            </div>

          </div>

          
          
        </v-card>


        <!-- <div v-for="(item) in items" :key="item.src" class="tw-flex tw-w-4/6 tw-flex-col tw-space-y-3 tw-whitespace-nowrap lg:tw-hidden">
          <div @click="goToDetail(item)">
              <a href="">
                  <img style="max-width:150px;" :src="item.src" class="tw-object-cover tw-h-52 tw-bg-red-600 tw-align-middle" alt="">
              </a>
          </div>
          <div @click="goToDetail(item)" style="max-width:150px" class="tw-w-auto tw-flex tw-flex-col tw-text-center">
            <div class="">
              <a style="max-width:150px" class="grey--text text--darken-4 tw-text-base tw-no-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden">
                {{item.title}}
              </a>
            </div>
            <div class="">
              <a style="max-width:150px" class="grey--text text--darken-4 tw-text-xs tw-underline tw-cursor-pointer tw-overflow-ellipsis tw-whitespace-nowrap tw-static tw-block tw-overflow-hidden" href="#">
                {{item.author}}
              </a>
            </div>
            <div>
              <v-rating background-color="grey lighten-1" readonly :value="item.rating" color="yellow accent-4" dense half-increments size="18"></v-rating>
            </div>  
          </div>
        </div>   -->
      </div>
    </div>
</template>

<script>
export default {
    name: "CarouselView",
    props: {
        items: { type: Array, required: true }
    },
    methods: {
        goToDetail(item) {
            this.$store.dispatch("selectBook", item)
            this.$router.push({ name: "BookDetail", params: { id: item.quick_info.id, book: item } })
        }
    }
}
</script>