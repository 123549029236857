<template>
    <div :key="bookID" v-if="!loading">
        <v-snackbar v-model="snackbar" class="font-weight-bold text-center" color="grey darken-4" :timeout="2500" top right>Book successfully added to cart.</v-snackbar>
        <div class="tw-px-0 lg:tw-px-6">
            <v-breadcrumbs :items="items">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>    
            </v-breadcrumbs>

        </div>
            
        <div v-if="book.volumeInfo" class="tw-block lg:tw-hidden">
            <div class="tw-flex-col tw-flex tw-space-y-1">
                <div class="tw-text-center tw-flex tw-flex-col tw-space-y-1">
                    <div>
                        <h1 class="tw-text-2xl tw-font-bold">
                            {{book.volumeInfo.title}}
                        </h1>
                    </div>
                    <div class="tw-text-sm">
                        by
                        <a class="black--text tw-no-underline tw-text-sm" href="#">{{book.quick_info.authors[0]}}</a>
                    </div>
                </div>
                <!-- <div class="tw-flex tw-flex-row tw-items-baseline tw-justify-center tw-space-x-4">
                    <div>
                        <v-rating background-color="grey lighten-1" readonly :value="5" color="yellow accent-4" dense half-increments size="16"></v-rating>
                    </div>
                    <div class="tw-text-xs tw-text-primary-800">
                        5 (300)
                    </div>    
                </div> -->
            </div>
            <div class="tw-mt-2 tw-flex tw-py-4 tw-flex-row tw-justify-center tw-w-full">
                <img style="max-width:400px;" :src="book.quick_info.imageLinks.thumbnail" class="tw-object-cover tw-h-96 tw-align-middle" alt="">
            </div>
            <!-- <div class="tw-p-4">
                <v-select v-model="format" :items="formats" hide-details label="Format" outlined style="border-radius:0px;"></v-select>
                <v-radio-group mandatory v-model="shippingType" column>
                    <v-radio label="Ship This Item" value="delivery">

                    </v-radio>
                    <v-radio label="Buy Online, Pick up in store" value="pickup">

                    </v-radio>

                </v-radio-group>
            </div> -->
            <div class=" tw-w-full tw-h-0.5 tw-bg-gray-200"></div>
            <div class="tw-p-4 tw-flex-col tw-flex tw-space-y-5">
                <div class=" tw-font-semibold">
                    Product Details
                </div>
                <!-- <div class="tw-grid-cols-2 tw-grid">
                    <div>
                        Sales Rank:
                    </div>
                    <div>
                        30
                    </div>
                </div> -->
                <div class="tw-grid-cols-2 tw-grid">
                    <div>
                        Pages:
                    </div>
                    <div>
                        {{book.volumeInfo.pageCount}}
                    </div>
                </div>
                <div class="tw-grid-cols-2 tw-grid">
                    <div>
                        Publication Date:
                    </div>
                    <div>
                        {{book.volumeInfo.publishedDate}}
                    </div>
                </div>
                <div class="tw-grid-cols-2 tw-grid">
                    <div>
                        Publisher:
                    </div>
                    <div>
                        {{book.volumeInfo.publisher}}
                    </div>
                </div>
            </div>
            <div class=" tw-w-full tw-h-0.5 tw-bg-gray-200"></div>
            <div class="tw-p-4 tw-flex-col tw-flex tw-space-y-5">
                <div class="tw-font-semibold">
                    Description
                </div>
                <div>
                    {{book.volumeInfo.description}}
                </div>
            </div>
            <div class=" tw-w-full tw-h-0.5 tw-bg-gray-200"></div>
            <div class="tw-p-4 tw-flex-col tw-flex tw-space-y-5">
                <!-- <div class=" tw-font-semibold">
                    Customer Reviews
                </div> -->
                <!-- <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-6">
                    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2">
                        <v-rating size="30" background-color="grey lighten-1" readonly :value="book.rating" color="yellow accent-4" dense half-increments></v-rating>
                        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-3">
                            <div>
                                5
                            </div>
                            <div class="tw-h-4 tw-w-0.5 tw-bg-gray-300"></div>
                            <div class="tw-text-primary-800">
                                300 reviews
                            </div>
                        </div>
                    </div>
                    <div>
                        <v-btn depressed dark class="primary-800 tw-rounded-none tw-normal-case">Write A Review</v-btn>
                    </div>
                </div> -->
                <!-- <div>
                    <div class="tw-bg-white tw-px-5 tw-pb-4">
                            <div class="tw-mb-1 tw-tracking-wide" >
                                
                                <div class="tw--mx-8 tw-px-8 tw-pb-3">
                                    <div class="tw-flex tw-items-center tw-mt-2">
                                        <div class="tw-w-1/5 tw-tracking-tighter">
                                            <span>5 star</span>
                                        </div>
                                        <div class="tw-w-3/5">
                                            <v-progress-linear
                                                background-color="grey lighten-3"
                                                color="primary-800"
                                                value="86"
                                                height="10"
                                            ></v-progress-linear>
                                        </div>
                                        <div class="tw-w-1/5 tw-text-gray-700 tw-pl-3">
                                            <span class="tw-text-sm">86%</span>
                                        </div>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-mt-2">
                                        <div class="tw-w-1/5 tw-tracking-tighter">
                                            <span>4 star</span>
                                        </div>
                                        <div class="tw-w-3/5">
                                            <v-progress-linear
                                                background-color="grey lighten-3"
                                                color="primary-800"
                                                value="7"
                                                height="10"
                                            ></v-progress-linear>
                                        </div>
                                        <div class="tw-w-1/5 tw-text-gray-700 tw-pl-3">
                                            <span class="tw-text-sm">7%</span>
                                        </div>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-mt-2">
                                        <div class="tw-w-1/5 tw-tracking-tighter">
                                            <span>3 star</span>
                                        </div>
                                        <div class="tw-w-3/5">
                                            <v-progress-linear
                                                background-color="grey lighten-3"
                                                color="primary-800"
                                                value="1"
                                                height="10"
                                            ></v-progress-linear>
                                        </div>
                                        <div class="tw-w-1/5 tw-text-gray-700 tw-pl-3">
                                            <span class="tw-text-sm">1%</span>
                                        </div>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-mt-2">
                                        <div class="tw-w-1/5 tw-tracking-tighter">
                                            <span>2 star</span>
                                        </div>
                                        <div class="tw-w-3/5">
                                            <v-progress-linear
                                                background-color="grey lighten-3"
                                                color="primary-800"
                                                value="4"
                                                height="10"
                                            ></v-progress-linear>
                                        </div>
                                        <div class="tw-w-1/5 tw-text-gray-700 tw-pl-3">
                                            <span class="tw-text-sm">4%</span>
                                        </div>
                                    </div>
                                    <div class="tw-flex tw-items-center tw-mt-2">
                                        <div class="tw-w-1/5 tw-tracking-tighter">
                                            <span>1 star</span>
                                        </div>
                                        <div class="tw-w-3/5">
                                            <v-progress-linear
                                                background-color="grey lighten-3"
                                                color="primary-800"
                                                value="2"
                                                height="10"
                                            ></v-progress-linear>
                                        </div>
                                        <div class="tw-w-1/5 tw-text-gray-700 tw-pl-3">
                                            <span class="tw-text-sm">2%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div> -->
                <!-- <div class="tw-grid tw-grid-cols-1 tw-divide-y-2">
                    <div v-for="i in 10" :key="i" class="tw-p-2 tw-flex tw-flex-col tw-space-y-3">
                        <div class="tw-flex tw-flex-col tw-space-y-2">
                            <div>
                                <v-rating size="14" background-color="grey lighten-1" readonly :value="book.rating" color="yellow accent-4" dense half-increments></v-rating>
                                <div class="tw-text-base tw-font-bold">
                                    Jim Smith
                                </div>
                            </div>
                            <div class="tw-font-bold tw-text-xl">
                                What a book!
                            </div>        
                        </div>
                        <div>
                            <p class="tw-text-sm">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                            </p>
                        </div>
                    </div>

                </div> -->

                <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-6">
                    <div class="tw-py-2 tw-flex tw-items-center tw-justify-between">
                        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
                            You May Also Like
                        </h1> 
                    </div>
                    <Carousel :items="related" />
                </div>

                <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-6 tw-mb-6">
                    <div class="tw-px-2 tw-py-2 tw-flex tw-items-center tw-justify-between">
                        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
                            Shop by Category
                        </h1>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-px-2">
                        <div @click="goToCategory(item)" v-for="(item, i) in shopby" :key="i" class="tw-border-gray-400 tw-h-48 tw-py-4 tw-border tw-flex-col tw-flex tw-items-center tw-space-y-3">
                            <div class="tw-font-semibold tw-text-sm">
                                {{item.category}}
                            </div>
                            <img style="max-width:85px;max-height:120px;" class="tw-block" :src="item.src" alt="">
                        </div>
                    </div>
                </div>
                    
            </div>
            <!-- <div class="tw-block md:tw-hidden">
                <AddToCart :item="book" />
            </div> -->

        </div>
        <div v-if="book.volumeInfo" :key="bookID" class="tw-hidden lg:tw-block">
            <div class="tw-flex tw-flex-col tw-space-y-16">

                <div class="tw-grid tw-grid-cols-2 tw-divide-x tw-divide-black">
                    <div class="tw-flex tw-border-t tw-border-b tw-border-black tw-py-10 tw-flex-row tw-justify-center tw-col-span-1 tw-w-full">
                        <img style="height:600px" :src="book.quick_info.imageLinks.thumbnail" class="tw-object-cover tw-align-middle" alt="">
                    </div>
                    <div class="tw-flex tw-p-10 tw-flex-col tw-border-t tw-border-b tw-border-black tw-py-4 tw-space-y-4 tw-divide-y">

                        <div class="">
                            <div class="tw-flex tw-flex-col tw-space-y-2 tw-py-4">
                                <div class="tw-text-3xl">
                                    <h1 class="book_detail_title">
                                        {{book.volumeInfo.title}}
                                    </h1>
                                </div>
                                <div>
                                    by <span>{{book.volumeInfo.authors[0]}}</span>
                                </div>
                                <div class="tw-flex tw-flex-row tw-items-baseline tw-space-x-4">
                                    <div>
                                        <v-rating background-color="grey lighten-1" readonly :value="0" color="yellow accent-4" dense half-increments size="16"></v-rating>
                                    </div>
                                    <div class="tw-text-xs tw-text-primary-800">
                                        0 (0)
                                    </div>    
                                </div>
                            </div>
                        </div>

                        <div class="tw-py-4 tw-flex tw-flex-col tw-space-4">
                            <div class="tw-flex tw-flex-row tw-items-center tw-space-x-3">
                                <p v-if="book.quick_info.isDiscounted" class="tw-font-bold tw-text-lg">
                                    {{(book.quick_info.retail_price * (1-book.quick_info.percentage)) | currency}}
                                </p>
                                <div>
                                    <p v-if="book.quick_info.isDiscounted" class="tw-font-bold tw-text-base tw-line-through tw-text-gray-400">
                                        {{book.quick_info.retail_price | currency}}
                                    </p>
                                    <p v-else class="tw-font-bold tw-text-base tw-text-gray-400">
                                        {{book.quick_info.retail_price | currency}}
                                    </p>


                                </div>
                                
                            </div>
                            <!-- <div>
                                <v-select v-model="format" :items="formats" hide-details label="Format" outlined style="border-radius:0px;"></v-select>
                                <v-radio-group mandatory v-model="shippingType" column>
                                    <v-radio label="Ship This Item" value="delivery">

                                    </v-radio>
                                    <v-radio label="Buy Online, Pick up in store" value="pickup">

                                    </v-radio>

                                </v-radio-group>
                            </div> -->
                            <!-- <div class="tw-w-full lg:tw-w-1/3">
                                <v-btn :loading="loading" @click="addBook(book)" style="border-radius:0px;" class="primary-800" dark depressed x-large block>
                                    Add To Cart
                                </v-btn>
                            </div> -->
                        </div>
                    </div>
                    
                </div>

                <div>
                    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-6">
                        <div class="tw-py-2 tw-flex tw-items-center tw-justify-start lg:tw-justify-center">
                            <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
                                You May Also Like
                            </h1> 
                        </div>
                        <Carousel :items="related" />
                    </div>
                </div>

                <div class="tw-px-10">
                    <v-tabs v-model="tab" background-color="transparent" grow>
                        <v-tab>
                            Description
                        </v-tab>
                        <v-tab>
                            Details
                        </v-tab>
                        <v-tabs-items class="tw-py-12" v-model="tab">
                            <v-tab-item>
                                {{book.volumeInfo.description}}

                            </v-tab-item>
                            <v-tab-item>
                                <div class="tw-p-4 tw-flex-col tw-flex tw-space-y-5">
                                    <div class="tw-grid-cols-2 tw-grid">
                                        <div>
                                            Sales Rank:
                                        </div>
                                        <div>
                                            30
                                        </div>
                                    </div>
                                    <div class="tw-grid-cols-2 tw-grid">
                                        <div>
                                            Pages:
                                        </div>
                                        <div>
                                            {{book.volumeInfo.pageCount}}
                                        </div>
                                    </div>
                                    <div class="tw-grid-cols-2 tw-grid">
                                        <div>
                                            Publication Date:
                                        </div>
                                        <div>
                                            {{book.volumeInfo.publishedDate}}
                                        </div>
                                    </div>
                                    <div class="tw-grid-cols-2 tw-grid">
                                        <div>
                                            Publisher:
                                        </div>
                                        <div>
                                            {{book.volumeInfo.publisher}}
                                        </div>
                                    </div>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </div>
                <div>
                    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-6 tw-mb-10">
                        <div class="tw-py-2 tw-flex tw-items-center tw-justify-start lg:tw-justify-center">
                            <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
                                Related Books
                            </h1> 
                        </div>
                        <Carousel :items="related" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="tw-bg-white tw-space-y-2 lg:tw-space-y-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
        <div>
            <v-progress-circular color="primary" indeterminate size="80"></v-progress-circular>
        </div>
    </div>
</template>

<script>

//import AddToCart from '../components/AddToCart.vue'
import Carousel from '../components/CarouselView.vue'

import { mapMutations, mapState } from 'vuex'

export default {
    components: {
        //AddToCart,
        Carousel
    },
    data() {
        return {
            tab: null,
            shopby: [
                { route: "fiction", category: "Fiction", src: "//prodimage.images-bn.com/pimages/9780316323987_p0_v3_s90x140.jpg" },
                { route: "business&economics", category: "Business & Economics", src: "//prodimage.images-bn.com/pimages/9781982187187_p0_v3_s90x140.jpg" },
                { route: "literature", category: "Literature", src: "//prodimage.images-bn.com/pimages/9780316492935_p0_v3_s90x140.jpg" },
                { route: "children", category: "Children", src: "//prodimage.images-bn.com/pimages/9780062975164_p0_v3_s90x140.jpg" },
                // { route: "teens-ya", category: "Teens & YA", src: "//prodimage.images-bn.com/pimages/9781250766564_p0_v3_s90x140.jpg" },
            ],
            shippingType: null,
            format: null,
            items: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Books',
                    disabled: false,
                    href: '/',
                }
            ],
            //book: {},
            id: "",
            price: {
                price: 50000,
                discount: true,
                percentage: 0.15
            },
            snackbar: false,
            bookInfo: {},
            formats: [
                { text: "Paperback", value: "paperback" },
            ],
            // related: [],
        }
    },
    beforeDestroy() {
        this.$store.dispatch("selectBook", {});
    },
    
    async mounted() {
        
        this.id = this.$route.params.id
        await this.$store.dispatch("getBooks")
        await this.$store.dispatch("fetchBookById", this.$route.params.id)
        
        //this.book = await this.$store.getters.getBookById(this.id)
        this.bookInfo = {
            id: this.id,
            price: this.book.price,
            discount: this.book.discount,
            percentage: this.book.percentage,
            title: this.book.title,
            img: this.book.src,
            author: this.book.author
        }

        // if (book.volumeInfo.categories) {
        //     book.quick_info.categories = book.volumeInfo.categories
        // } else {
        //     book.quick_info.categories = null
        // }

        // let category = this.book.quick_info.categories[0]
        
        // let categories = this.books.filter(book => book.quick_info.categories)
        
        // this.related = categories.filter(book => book.quick_info.categories.includes(category)).slice(0, 5)
        
    },
    methods: {
        ...mapMutations({ addProduct: "shoppingcart/addBook"}),
        addBook(book) {
            this.addProduct(book)
            this.snackbar = true
        },
        goToCategory(item) {
            this.$router.push({ name: "CategoryDetail", params: { id: item.route } })
        }
    },
    computed: {
        ...mapState({ book: state => state.books.selectedBook, books: state => state.books.books, loading: state => state.books.loading, bookID: state => state.books.id, related: state => state.books.related }),
        // related() {
        //     let category = this.book.quick_info.categories[0]
        //     let categories = this.books.filter(book => book.quick_info.categories)
        //     return categories.filter(book => book.quick_info.categories.includes(category)).slice(0, 5)
        // }
    },
    watch: {
        async bookID(newVal) {
            await this.$store.dispatch("fetchBookById", newVal)
            this.id = newVal
            this.bookInfo = {
                id: this.id,
                price: this.book.price,
                discount: this.book.discount,
                percentage: this.book.percentage,
                title: this.book.title,
                img: this.book.src,
                author: this.book.author
            }
            // let category = this.book.quick_info.categories[0]

        
            // let categories = this.books.filter(book => book.quick_info.categories)
        
            // this.related = categories.filter(book => book.quick_info.categories.includes(category)).slice(0, 5)
        }
    }
}
</script>

<style>
.book_detail_title {
    font-family: 'Zen Antique', serif;
}
</style>