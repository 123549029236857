import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                'primary' : '#5D1049',
                'primary-800' : '#5D1049',
                'primary-900' : '#4E0D3A',
                'primary-700' : '#EF4444',
                'secondary-700' : '#E30425',
            }
        }
    }
});
