<template>
    <div class="tw-container tw-space-y-4 lg:tw-space-y-12 tw-flex tw-bg-pink-50 tw-flex-col tw-items-center tw-w-full tw-py-4 tw-px-2 md:tw-py-12 md:tw-px-10 md:tw-max-w-full tw-mx-auto">
        <h1 class="tw-font-bold tw-text-2xl md:tw-text-3xl lg:tw-text-4xl">Order Tracking</h1>
        <section class="tw-flex tw-py-3 lg:tw-py-12 tw-flex-col tw-bg-white tw-border tw-justify-center tw-border-gray-200 tw-w-12/12 tw-container lg:tw-w-9/12">
            <div class="tw-flex tw-flex-col tw-px-3 lg:tw-px-8 tw-space-y-2 lg:tw-space-y-8">
                <p>To track your order please enter your Order ID in the box below and press the "Track" button. This was given to you on your receipt and in the confirmation email you should have received.</p>
                
            </div>
            <div class="tw-flex tw-flex-col tw-px-3 lg:tw-px-8 tw-space-y-2 lg:tw-space-y-8">
                <v-alert v-if="error" type="error" text>{{ error }}</v-alert>
            </div>
            <validation-observer
                ref="observer"
            >
                <v-form @submit.prevent="submit" ref="form">
                    <div class="tw-flex tw-flex-col tw-px-3 lg:tw-px-8 tw-space-y-2 lg:tw-space-y-8">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Order Number"
                            rules="required"
                        >
                        
                            <v-text-field v-model="orderNumber" :error-messages="errors" label="Order Number" outlined class="tw-rounded-none"></v-text-field>
                        </validation-provider>
                        
                        <v-btn :loading="loading" :disabled="loading" type="submit" x-large tile depressed color="primary" class="tw-normal-case">
                            Track
                        </v-btn>
                    </div>

                </v-form>
            </validation-observer>
                    
            
        </section>
    </div>
</template>

<script>

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { mapState } from 'vuex'

setInteractionMode('eager')


extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

export default {
    computed: {
        ...mapState({ loading: state => state.orders.loading, error: state => state.orders.error })
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            orderNumber: ''
        }
    },
    methods: {
        async submit() {
            await this.$store.dispatch("getOrderById", this.orderNumber)

            if(!this.error) {
                await this.$router.push({ name: 'OrderReceived', params: { id: this.orderNumber } })
            }  
        }
    }
    
}
</script>