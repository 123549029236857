import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase/app';
import router from './router'
import store from './store'
import "tailwindcss/tailwind.css"
import vuetify from './plugins/vuetify'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';
import InstantSearch from 'vue-instantsearch';
import VueSlickCarousel from 'vue-slick-carousel'

Vue.use(VueSlickCarousel)
Vue.use(InstantSearch);

Vue.config.productionTip = false



var firebaseConfig = {
  apiKey: "AIzaSyDSK8IFlKtie7yD_Kgh2xIcw1jr73lYtus",
  authDomain: "adamspagesadmin.firebaseapp.com",
  projectId: "adamspagesadmin",
  storageBucket: "adamspagesadmin.appspot.com",
  messagingSenderId: "207752832425",
  appId: "1:207752832425:web:d9f545790f575ad25a10b8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const fb = firebase.auth.EmailAuthProvider;

Vue.filter("currency", (value) => new Intl.NumberFormat("en-Ng",
      { style: "currency",  currency: "NGN"}).format(value));

Vue.filter('allcaps', function(value) {
      if(!value) return ''
      value = value.toString()
      return value.toUpperCase()
})

Vue.filter("timestampToDateString", (value) => new Date(value).toDateString());

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
