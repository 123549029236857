<template>
  <div v-if="!loading" class="tw-container tw-relative">
    <div class="tw-flex tw-px-0 tw-py-4 md:tw-py-0 md:tw-px-0 md:tw-grid-cols-3 tw-space-y-0 md:tw-space-x-6">
      
      <div class="tw-flex tw-w-full md:tw-w-max-2xl tw-space-y-4">
        
        <div class="tw-mx-auto tw-w-full tw-text-center">
          <!-- <v-img contain :src="items[0].src"></v-img> -->
          <v-img contain src="https://firebasestorage.googleapis.com/v0/b/adamspagesadmin.appspot.com/o/b1678ed9-0622-475c-9029-d25ec4a7d15b.JPG?alt=media&token=1e09cb23-8baa-41ec-aacf-fb64c3ba2f51" lazy-src="https://firebasestorage.googleapis.com/v0/b/adamspagesadmin.appspot.com/o/b1678ed9-0622-475c-9029-d25ec4a7d15b.JPG?alt=media&token=1e09cb23-8baa-41ec-aacf-fb64c3ba2f51">
            
          </v-img>
        </div>
        
      </div>
      <!-- <div class="tw-col-span-1 tw-gap-4 tw-grid tw-mt-6 md:tw-mt-0"> -->
      <!-- <div class="tw-hidden md:tw-flex tw-w-full md:tw-w-1/3 tw-space-y-4 tw-flex-col tw-mt-6 md:tw-mt-0">
        <div class="tw-bg-green-500 tw-p-6 md:tw-p-10 md:tw-h-full">
          <div class="tw-border-white tw-border tw-text-center tw-flex tw-flex-col tw-space-y-1 md:tw-space-y-3 tw-p-2 md:tw-p-8 tw-items-center tw-justify-center tw-h-full">
              <h1 class="tw-text-white tw-font-bold">GET FREE DELIVERY</h1>
              <h6 class="tw-text-white tw-font-bold tw-text-4xl">50%</h6>
              <v-btn text dark>PURCHASE</v-btn>
          </div>
          
        </div>
        <div class="tw-bg-pink-500 tw-p-6 md:tw-p-10 md:tw-h-full">
          <div class="tw-border-white tw-border tw-text-center tw-flex tw-flex-col tw-space-y-1 md:tw-space-y-3 tw-p-2 md:tw-p-8 tw-items-center tw-justify-center tw-h-full">
              <h1 class="tw-text-white tw-font-bold">GET FREE DELIVERY</h1>
              <h6 class="tw-text-white tw-font-bold tw-text-2xl">On orders of &#8358;40,000 or more.</h6>
              <v-btn text dark>PURCHASE</v-btn>
            </div>
        </div>
      </div> -->
    </div>
    
    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-4 md:tw-mt-16">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Trending Books
        </h1>
        
      </div>
      <Carousel :items="popular" />
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Non Fiction
        </h1>
        <a href="/category/non-fiction" class="tw-inline-flex tw-w-4/12 tw-space-x-2 tw-items-center tw-justify-end tw-group tw-cursor-pointer">
          <div class="tw-text-base tw-font-semibold tw-leading-normal tw-text-secondary-700">See all</div>
          <svg class="ionicon tw-transform group-hover:tw-translate-x-1 tw-ease-in-out tw-duration-500 tw-text-secondary-700" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="18" height="18"><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
        </a>  
      </div>
      <Carousel :items="non_fiction" />
    </div>
    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Nigerian Authors
        </h1>
        <a href="/category/non-fiction" class="tw-inline-flex tw-w-4/12 tw-space-x-2 tw-items-center tw-justify-end tw-group tw-cursor-pointer">
          <div class="tw-text-base tw-font-semibold tw-leading-normal tw-text-secondary-700">See all</div>
          <svg class="ionicon tw-transform group-hover:tw-translate-x-1 tw-ease-in-out tw-duration-500 tw-text-secondary-700" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="18" height="18"><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
        </a>  
      </div>
      <Carousel :items="nigerian" />
    </div>
    

    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10 tw-px-0 md:tw-px-10">
      <div class="tw-py-2 tw-flex tw-items-center tw-px-4 md:tw-px-0 tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          New Releases
        </h1>  
      </div>
      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-6 tw-items-center">
        <div class="tw-hidden lg:tw-flex tw-flex-col tw-space-y-8 tw-px-6 tw-justify-center tw-h-full lg:tw-col-span-2 tw-bg-pink-200">
          <img src="https://demo2.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/350x282/img1.png" alt="">
          <div>
            <h2 class="tw-font-base tw-text-5xl">
              Get Extra
            </h2>
            <h2 class="tw-text-5xl tw-text-primary-700">Sale - 25% Off</h2>

          </div>
          <v-btn x-large tile depressed class="tw-normal-case primary">
            View More
          </v-btn>
            
        </div>
        <div class="tw-col-span-4 tw-grid tw-grid-cols-1 lg:tw-grid-cols-3">
          <BookView class="tw-hidden lg:tw-block" v-for="(item, i) in new_releases" :key="i" :item="item" />
          <Carousel class="tw-block lg:tw-hidden" :items="new_releases" />

        </div>

      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Children Books
        </h1>
        <a href="/category/children" class="tw-inline-flex tw-w-4/12 tw-space-x-2 tw-items-center tw-justify-end tw-group tw-cursor-pointer">
          <div class="tw-text-base tw-font-semibold tw-leading-normal tw-text-secondary-700">See all</div>
          <svg class="ionicon tw-transform group-hover:tw-translate-x-1 tw-ease-in-out tw-duration-500 tw-text-secondary-700" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="18" height="18"><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
        </a>  
      </div>
      <Carousel :items="kids" />
    </div>
    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Young Adults
        </h1>
        <a href="/category/youngadult" class="tw-inline-flex tw-w-4/12 tw-space-x-2 tw-items-center tw-justify-end tw-group tw-cursor-pointer">
          <div class="tw-text-base tw-font-semibold tw-leading-normal tw-text-secondary-700">See all</div>
          <svg class="ionicon tw-transform group-hover:tw-translate-x-1 tw-ease-in-out tw-duration-500 tw-text-secondary-700" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="18" height="18"><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
        </a>  
      </div>
      <Carousel :items="young_adults" />
    </div>
    <div class="tw-flex tw-flex-col tw-space-y-3 tw-mt-10">
      <div class="tw-px-4 md:tw-px-10 tw-py-2 tw-flex tw-items-center tw-justify-between">
        <h1 class="tw-text-lg tw-font-bold grey--text text--darken-4">
          Fiction
        </h1>
        <a href="/category/fiction" class="tw-inline-flex tw-w-4/12 tw-space-x-2 tw-items-center tw-justify-end tw-group tw-cursor-pointer">
          <div class="tw-text-base tw-font-semibold tw-leading-normal tw-text-secondary-700">See all</div>
          <svg class="ionicon tw-transform group-hover:tw-translate-x-1 tw-ease-in-out tw-duration-500 tw-text-secondary-700" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width="18" height="18"><title>Arrow Forward</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/></svg>
        </a>  
      </div>
      <Carousel :items="fiction" />
    </div>
    
  </div>
  <div v-else class="tw-bg-white tw-space-y-2 lg:tw-space-y-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
    <div>
        <v-progress-circular color="primary" indeterminate size="80"></v-progress-circular>
    </div>
  </div>
</template>

<script>

//import CategoryList from '../components/CategoryList.vue'
import Carousel from '../components/CarouselView.vue'
import BookView from '../components/BookView.vue'
import { mapState } from "vuex";

export default {
  components: {
    //CategoryList,
    Carousel,
    BookView
  },
  async created() {
    await this.$store.dispatch("getBooks")
    await this.$store.dispatch("getHomePage")
  },
  computed: {
    ...mapState({ loading: state => state.books.loading, books: state => state.books.books, new_releases: state => state.books.new_releases, popular: state => state.books.popular, kids: state => state.books.kids, nigerian: state => state.books.nigerian, non_fiction: state => state.books.non_fiction, fiction: state => state.books.fiction, young_adults: state => state.books.young_adults, })
  },
  data() {
    return {
      shopby: [
        { category: "Summer Reading", src: "//prodimage.images-bn.com/pimages/9780316323987_p0_v3_s90x140.jpg" },
        { category: "Fiction", src: "//prodimage.images-bn.com/pimages/9781982187187_p0_v3_s90x140.jpg" },
        { category: "Nonfiction", src: "//prodimage.images-bn.com/pimages/9780316492935_p0_v3_s90x140.jpg" },
        { category: "Juvenile Fiction", src: "//prodimage.images-bn.com/pimages/9780062975164_p0_v3_s90x140.jpg" },
        { category: "Teens & YA", src: "//prodimage.images-bn.com/pimages/9781250766564_p0_v3_s90x140.jpg" },
      ],
      items: [
        { author: "Trish Doller", rating: 5, title: "Brother's Book", src: "//dispatch.barnesandnoble.com/content/dam/ccr/homepage/daily/2021/06/09/Gateway_Quote_A4_06-09-mobile.jpg" },
        { author: "Trish Doller", rating: 5, title: "Mother's Book", src: "//dispatch.barnesandnoble.com/content/dam/ccr/homepage/daily/2021/06/01/Gateway_Quote_A2_06-01-mobile.jpg" },
        { author: "Trish Doller", rating: 5, title: "Brother's Book", src: "//dispatch.barnesandnoble.com/content/dam/ccr/homepage/daily/2021/06/01/Gateway_Quote_A3_06-01-mobile.jpg" },
        { author: "Trish Doller", rating: 5, title: "Dad's Book", src: "//dispatch.barnesandnoble.com/content/dam/ccr/homepage/daily/2021/06/09/Gateway_Quote_A2_06-09-mobile.jpg" }
      ],
      categories: [
        { name: "NEW RELEASES" },
        { name: "BESTSELLERS" },
        { name: "COMING SOON" },
        { name: "WORKBOOKS" },
        { name: "SUMMER READING" },
      ]
    }
  }
}
</script>

